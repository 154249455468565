import Search from "../../component/search";
import DropDown from "../../component/DropDown";
import {
  Months,
  BagianConstant,
  constantYear,
  kepalaShiftTimOne,
  kepalaShiftTimTwo,
} from "../../constant";
import { useDashboard } from "../dashboard/useDashboard";
import React, { useEffect, useState } from "react";
import AccordionAbsensi from "../../component/AccordionAbsensi/AccordionAbsensi";
import { monthDefaultFormat } from "../../util/formatDate";
import "./Absensi.css";
import axios from "axios";
import * as XLSX from "xlsx";

const Absensi = () => {
  const {
    jsonTim,
    valueSearch,
    setValueSearch,
    searchFeature,
    editParams,
    setFilterMonth,
    setFilterRole,
    handleExportExcel,
    editOTParams,
    setFilterDate,
    addNewAbsent,
    setClockIn,
    setDateIn,
    setClockOut,
    setDateOut,
    setKtpNumber,
    isFilter,
    setIsFilter,
    setFilterDateFrom,
    setFilterDateUntil,
    handleFilterUntil,
    setFilterYear,
    filterYear,
    filterKepala,
    setFilterKepala,
  } = useDashboard();

  const d = new Date();
  const getMonth = d.getMonth();
  const [subMenuAbsensi, setSubMenuAbsensi] = useState("Absensi");
  const [file, setFile] = useState(null);

  useEffect(() => {
    setFilterMonth(monthDefaultFormat[getMonth]);
  }, []);

  let dataTemp = searchFeature(valueSearch);

  //handle error absen kurang
  // const handleConvert = () => {
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       const data = e.target.result;
  //       const workbook = XLSX.read(data, { type: "binary" });
  //       const sheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[sheetName];
  //       const json = XLSX.utils.sheet_to_json(worksheet);

  //       await axios.post(`${process.env.REACT_APP_ENDPOINT}/custom`, {
  //         data: json,
  //       });
  //     };
  //     reader.readAsBinaryString(file);
  //   }
  // };

  return (
    <>
      {isFilter ? (
        <div className="popup_container">
          <div className="popup_wrapper">
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <p>Dari</p>
                <input
                  style={{ height: "27px", width: "112px" }}
                  type="date"
                  onChange={(e) => setFilterDateFrom(e.target.value)}
                />
              </div>
              <div>
                <p>Sampai</p>
                <input
                  style={{ height: "27px", width: "112px" }}
                  type="date"
                  onChange={(e) => setFilterDateUntil(e.target.value)}
                />
              </div>
            </div>
            <button
              style={{
                float: "right",
                marginTop: "24px",
                fontSize: "16px",
                background: "red",
                color: "white",
                border: "none",
                padding: "6px 12px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={() => handleFilterUntil()}
            >
              Cari
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <p
          style={
            subMenuAbsensi === "Absensi"
              ? {
                  padding: "6px 0px",
                  borderBottom: "3px solid dodgerblue",
                  cursor: "pointer",
                }
              : { padding: "6px 0px", cursor: "pointer" }
          }
          onClick={() => setSubMenuAbsensi("Absensi")}
        >
          Absensi
        </p>
        <p
          style={
            subMenuAbsensi === "AddNewAbsensi"
              ? {
                  padding: "6px 0px",
                  borderBottom: "3px solid dodgerblue",
                  cursor: "pointer",
                }
              : { padding: "6px 0px", cursor: "pointer" }
          }
          onClick={() => setSubMenuAbsensi("AddNewAbsensi")}
        >
          Add New Absensi
        </p>
      </div>
      {subMenuAbsensi === "Absensi" ? (
        <>
          {/* //handle error absen kurang */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "12px 12px 0 0",
            }}
          >
            <input
              type="file"
              accept=".xls,.xlsx"
              onChange={(e) => setFile(e.target.files[0])}
              style={{ cursor: "pointer", width: "25%" }}
            />
            <button
              style={{
                cursor: "pointer",
                padding: "12px",
                cursor: "pointer",
                background: "gold",
                border: "none",
                borderRadius: "6px",
                fontWeight: "bold",
              }}
              onClick={handleConvert}
            >
              IMPORT
            </button>
          </div> */}
          {/* //handle error absen kurang */}
          <p style={{ fontWeight: "bold" }}>Absensi</p>
          <div className="flex_absensi">
            <Search valueSearch={(e) => setValueSearch(e.target.value)} />
            <div className="wrapper_filter_absensi">
              <div
                style={{
                  background: "dodgerblue",
                  marginBottom: "18px",
                  marginRight: "12px",
                  cursor: "pointer",
                  color: "white",
                  padding: "6px 6px 0 6px",
                  borderRadius: "6px",
                  fontSize: "14px",
                }}
                onClick={() => setIsFilter(true)}
              >
                Filter Range
              </div>
              <input
                style={{ height: "27px", width: "112px" }}
                type="date"
                onChange={(e) => setFilterDate(e.target.value)}
              />
              <DropDown
                dataOption={BagianConstant}
                setFilter={setFilterRole}
                placeHolder="Pilih Bagian"
              />
              <DropDown
                dataOption={Months}
                setFilter={setFilterMonth}
                placeHolder="Pilih Bulan"
              />
              <DropDown
                dataOption={constantYear}
                setFilter={setFilterYear}
                value={filterYear}
                placeHolder="Pilih Tahun"
              />
              <DropDown
                dataOption={
                  jsonTim.tim === 1 ? kepalaShiftTimOne : kepalaShiftTimTwo
                }
                setFilter={setFilterKepala}
                value={filterKepala}
                placeHolder="Pilih Kepala"
              />

              <div className="export_btn" onClick={() => handleExportExcel()}>
                Export
              </div>
            </div>
          </div>
          <div style={{ width: "0", minWidth: "100%", overflow: "auto" }}>
            <table className="table_dashboard">
              <tr>
                <th>No.</th>
                <th>Nama</th>
                <th>Absen</th>
                <th>Lembur</th>
                <th>Jam Lebih</th>
              </tr>
              {dataTemp &&
                dataTemp.map((data, index) => {
                  const sortedAbsent =
                    data &&
                    data.absent.sort(function (a, b) {
                      let dateA = new Date(a.clockIn.day),
                        dateB = new Date(b.clockIn.day);
                      return dateB - dateA;
                    });
                  const sortedOverTime =
                    data &&
                    data.overTimeAbsent.sort(function (a, b) {
                      let dateA = new Date(a.clockIn),
                        dateB = new Date(b.clockIn);
                      return dateB - dateA;
                    });
                  data &&
                    data.absent.forEach(function () {
                      editParams.push(false);
                    });
                  data &&
                    data.overTimeAbsent.forEach(function () {
                      editOTParams.push(false);
                    });
                  return (
                    <AccordionAbsensi
                      data={data}
                      sortedAbsent={sortedAbsent}
                      sortedOverTime={sortedOverTime}
                      index={index}
                    />
                  );
                })}
            </table>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <div>
              <p>Tanggal Masuk</p>
              <input
                style={{ height: "27px", width: "188px" }}
                type="date"
                onChange={(e) => setDateIn(e.target.value)}
              />
            </div>
            <div>
              <p>Tanggal Keluar</p>
              <input
                style={{ height: "27px", width: "188px" }}
                type="date"
                onChange={(e) => setDateOut(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <div>
              <p>Jam Masuk</p>
              <input
                style={{ height: "27px", width: "188px" }}
                type="time"
                onChange={(e) => setClockIn(e.target.value)}
              />
            </div>
            <div>
              <p>Jam Keluar</p>
              <input
                style={{ height: "27px", width: "188px" }}
                type="time"
                onChange={(e) => setClockOut(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <div>
              <p>No. KTP</p>
              <input
                style={{ height: "27px", width: "188px" }}
                type="number"
                onChange={(e) => setKtpNumber(e.target.value)}
              />
            </div>
            <div
              style={{
                width: "188px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <button
                style={{
                  width: "100px",
                  cursor: "pointer",
                  height: "32px",
                  border: "none",
                  background: "red",
                  color: "white",
                  borderRadius: "8px",
                }}
                onClick={() => addNewAbsent()}
              >
                Kirim
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Absensi;
