import React, { useState } from "react";
import { formatDate, validateTime } from "../../util/formatDate";
import EditAbsent from "../../component/EditAbsent";
import ButtonEdit from "../../component/ButtonEdit";
import { useDashboard } from "../../Pages/dashboard/useDashboard";
import "./AccordionAbsensi.css";

const AccordionAbsensi = (props) => {
  const { data, sortedAbsent, sortedOverTime, index } = props;
  const {
    handleEdit,
    isEditAbsen,
    initialEdit,
    handleKirim,
    setClockInValueEdit,
    setClockOutValueEdit,
    isEditOverTime,
    initialEditOT,
    setDateClockOut,
    dateClockOut,
    setDateClockIn,
    dateClockIn,
  } = useDashboard();
  const [initialExpand, setInitialExpand] = useState(false);
  const role = localStorage.getItem("user");
  const jsonRole = JSON.parse(role);

  return (
    <tr>
      <td style={{ padding: "12px" }}>{index + 1}</td>
      <td
        style={{ cursor: "pointer" }}
        onClick={() => setInitialExpand(!initialExpand)}
      >
        {data.name}
      </td>
      <td>
        {initialExpand !== true ? (
          <div>
            <p style={{ margin: "0 0 8px 0" }}>
              <b>- </b>
              {data.absent.length > 0
                ? `${formatDate(sortedAbsent[0], "fullDate")} -
                  ${formatDate(sortedAbsent[0], "outFulldate")}`
                : ""}
            </p>
            <p
              style={
                validateTime(sortedAbsent[0], "normalAbsent") === "tepat"
                  ? { margin: "0 0 8px 0", color: "#3ebd81" }
                  : { margin: "0 0 8px 0", color: "red" }
              }
            >
              {data.absent.length > 0
                ? `${formatDate(sortedAbsent[0], "clockIn").toLocaleTimeString(
                    "en-GB"
                  )} - ${
                    sortedAbsent[0].clockOut.time === null
                      ? ""
                      : formatDate(
                          sortedAbsent[0],
                          "clockOut"
                        ).toLocaleTimeString("en-GB")
                  }`
                : ""}
            </p>
          </div>
        ) : (
          <div
            style={
              initialExpand === true ? { dispaly: "" } : { display: "none" }
            }
          >
            {sortedAbsent &&
              sortedAbsent.map((absent, index) => {
                return (
                  <div style={{ marginBottom: "12px" }}>
                    <p style={{ margin: "0 0 8px 0" }}>
                      <b>-</b>{" "}
                      {data.absent.length > 0
                        ? `${formatDate(absent, "fullDate")} -
                        ${formatDate(absent, "outFulldate")}`
                        : ""}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {isEditAbsen && initialEdit[index] ? (
                        <EditAbsent
                          setClockInValueEdit={setClockInValueEdit}
                          setClockOutValueEdit={setClockOutValueEdit}
                          selected={dateClockOut}
                          selectedIn={dateClockIn}
                          onChange={(e) => setDateClockOut(e)}
                          onChangeIn={(e) => setDateClockIn(e)}
                        />
                      ) : (
                        <p
                          style={
                            validateTime(absent) === "tepat"
                              ? {
                                  margin: "0 0 8px 0",
                                  color: "#3ebd81",
                                }
                              : {
                                  margin: "0 0 8px 0",
                                  color: "red",
                                }
                          }
                        >
                          {data.absent.length > 0
                            ? `${formatDate(
                                absent,
                                "clockIn"
                              ).toLocaleTimeString("en-GB")} - ${
                                absent.clockOut.time === null
                                  ? ""
                                  : formatDate(
                                      absent,
                                      "clockOut"
                                    ).toLocaleTimeString("en-GB")
                              }`
                            : ""}
                        </p>
                      )}
                      {jsonRole.role === "hrd_admin" ||
                      jsonRole.role === "super_user" ? (
                        <ButtonEdit
                          isEdit={isEditAbsen && initialEdit[index]}
                          handleKirim={() =>
                            handleKirim(absent, data.id, "absent")
                          }
                          handleEdit={() => handleEdit(index, "absent")}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </td>
      <td>
        {initialExpand !== true ? (
          <div>
            <p style={{ margin: "0 0 8px 0" }}>
              <b>-</b>
              {data.overTimeAbsent.length > 0
                ? formatDate(sortedOverTime[0], "fullDate")
                : ""}
            </p>
            <p
              style={{
                margin: "0 0 8px 0",
                color: "#3ebd81",
              }}
            >
              {data.overTimeAbsent.length > 0
                ? `${formatDate(
                    sortedOverTime[0],
                    "clockIn"
                  ).toLocaleTimeString("en-GB")} - ${formatDate(
                    sortedOverTime[0],
                    "clockOut"
                  ).toLocaleTimeString("en-GB")}`
                : ""}
            </p>
          </div>
        ) : (
          <div>
            {sortedOverTime.map((dataOverTime, index) => {
              return (
                <div>
                  <p style={{ margin: "0 0 8px 0" }}>
                    <b>-</b>
                    {data.overTimeAbsent.length > 0
                      ? formatDate(dataOverTime, "fullDate")
                      : ""}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {isEditOverTime && initialEditOT[index] ? (
                      <EditAbsent
                        setClockInValueEdit={setClockInValueEdit}
                        setClockOutValueEdit={setClockOutValueEdit}
                      />
                    ) : (
                      <p
                        style={{
                          margin: "0 0 8px 0",
                          color: "#3ebd81",
                        }}
                      >
                        {data.overTimeAbsent.length > 0
                          ? `${formatDate(
                              dataOverTime,
                              "clockIn"
                            ).toLocaleTimeString("en-GB")} - ${formatDate(
                              dataOverTime,
                              "clockOut"
                            ).toLocaleTimeString("en-GB")}`
                          : ""}
                      </p>
                    )}
                    {jsonRole.role === "hrd_admin" ||
                    jsonRole.role === "super_user" ? (
                      <ButtonEdit
                        isEdit={isEditOverTime && initialEditOT[index]}
                        handleKirim={() =>
                          handleKirim(dataOverTime, data.id, "overTime")
                        }
                        handleEdit={() => handleEdit(index, "overTime")}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </td>
      <td>
        {data.jamLebih && data.jamLebih !== 0 ? `${data.jamLebih} Jam` : ""}{" "}
      </td>
    </tr>
  );
};
export default AccordionAbsensi;
