const Search = (props) => {
  const { valueSearch } = props;
  return (
    <div style={{ marginBottom: "21px", display: "flex" }}>
      <input type="text" onChange={valueSearch} />
      <div
        style={{
          margin: "0 12px",
          cursor: "pointer",
          color: "white",
          background: "dodgerblue",
          padding: "4px 8px",
          borderRadius: "4px",
        }}
      >
        Cari
      </div>
    </div>
  );
};
export default Search;
