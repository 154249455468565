import Login from "./Pages/Login/Login";
import { Route, BrowserRouter } from "react-router-dom";
import Dashboard from "./Pages/dashboard/dashboard";
import "./App.css";
import { createBrowserHistory } from "history";

const App = () => {
  const history = createBrowserHistory();
  if (history.location.pathname === "/") {
    history.push("/login");
  }

  return (
    <>
      <BrowserRouter>
        <Route exact path={"/login"} component={Login}>
          <div className="parent" style={{ justifyContent: "center" }}>
            <Login />
          </div>
        </Route>
        <Route exact path={"/dashboard"} component={Dashboard}>
          <div className="parent">
            <Dashboard />
          </div>
        </Route>
      </BrowserRouter>
    </>
  );
};

export default App;
