export const Months = [
  { name: "Januari", value: "Jan" },
  { name: "Febuari", value: "Feb" },
  { name: "Maret", value: "Mar" },
  { name: "April", value: "Apr" },
  { name: "Mei", value: "May" },
  { name: "Juni", value: "Jun" },
  { name: "Juli", value: "Jul" },
  { name: "Agustus", value: "Aug" },
  { name: "September", value: "Sep" },
  { name: "Oktober", value: "Oct" },
  { name: "November", value: "Nov" },
  { name: "Desember", value: "Dec" },
];

export const RoleConstant = [
  "finance_admin",
  "finance_user",
  "hrd_admin",
  "hrd_user",
  "mold_admin",
  "mold_user",
  "mesin_admin",
  "mesin_user",
  "p3_user",
  "ppic_admin",
  "ppic_user",
  "produksi_user",
  "produksi_leader",
  "produksi_admin",
  "pr_admin",
  "pr_user",
  "qc_admin",
  "qc_user",
  "security",
  "sp_admin",
  "sp_user",
  "warehouse_admin",
  "warehouse_user",
  "warehouse_mixing",
  "warehouse_crusher",
  "warehouse_gbj_admin",
  "warehouse_gbj_user",
];

export const BagianConstant = [
  { name: "Produksi", value: "Produksi" },
  { name: "Finishing", value: "Finishing" },
  { name: "Printing", value: "Printing" },
  { name: "Gudang", value: "Gudang" },
  { name: "QC", value: "QC" },
  { name: "PPIC", value: "PPIC" },
  { name: "Maintenance Mold", value: "Maintenance Mold" },
  { name: "Maintenance Mesin", value: "Maintenance Mesin" },
  { name: "Finance", value: "Finance" },
  { name: "Purchasing", value: "Purchasing" },
  { name: "HRD", value: "HRD" },
  { name: "MR", value: "MR" },
  { name: "Dokumen Kontrol", value: "Dokumen Kontrol" },
  { name: "Security", value: "Security" },
  { name: "Kebersihan", value: "Kebersihan" },
];

export const jenisKaryawan = [
  { name: "Kontrak", value: "Contract" },
  { name: "Permanen", value: "Permanent" },
];

export const constantYear = [
  { name: "2021", value: "2021" },
  { name: "2022", value: "2022" },
  { name: "2023", value: "2023" },
  { name: "2024", value: "2024" },
];

export const kepalaShiftTimOne = [
  { name: "ACHMAD", value: "ACHMAD" },
  { name: "SARIPUDIN", value: "SARIPUDIN" },
  { name: "SUPRIADI", value: "SUPRIADI" },
  { name: "MEI", value: "MEI" },
  { name: "DEWI", value: "DEWI" },
];

export const kepalaShiftTimTwo = [
  { name: "EKO", value: "EKO" },
  { name: "FINDRI", value: "FINDRI" },
  { name: "ROCHENDY", value: "ROCHENDY" },
  { name: "NINA", value: "NINA" },
  { name: "AISAH", value: "AISAH" },
];
