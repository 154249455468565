import { useEffect, useState } from "react";
import "./AccordionEditUser.css";
import {
  BagianConstant,
  RoleConstant,
  kepalaShiftTimOne,
  kepalaShiftTimTwo,
} from "../../constant";
import axios from "axios";

const AccordionEditUser = ({
  data,
  editType,
  index,
  peopleInfo,
  setPeopleInfo,
}) => {
  const getToken = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;
  const roleUser = localStorage.getItem("user");
  const jsonRole = JSON.parse(roleUser);
  const [initialExpand, setInitialExpand] = useState(false);
  const id = data.id;
  const [noNPWP, setNoNPWP] = useState(data.npwpNumber);
  const [noRekening, setNoRekening] = useState(data.bankAccountNumber);
  const [nama, setNama] = useState(data.name);
  const [email, setEmail] = useState(data.email);
  const [telepon, setTelepon] = useState(data.phoneNumber);
  const [bagian, setBagian] = useState(data.bagian);
  const [alamat, setAlamat] = useState(data.alamat);
  const [shift, setShift] = useState(data.shift);
  const [valueEmployeeType, setValueEmployeeType] = useState(data.employeeType);
  const [role, setRole] = useState(data.role);
  const [posisi, setPosisi] = useState(data.position);
  const [tempatTanggalLahir, setTempatTanggalLahir] = useState(
    data.tempatTanggalLahir
  );
  const [statusPerkawinan, setStatusPerkawinan] = useState(
    data.statusPerkawinan
  );
  const [jumlahAnak, setJumlahAnak] = useState(data.jumlahAnak);

  const [gajiPokok, setGajiPokok] = useState(data.basicSallary);
  const [kurangBayar, setKurangBayar] = useState(data.kurangBayar);
  const [potonganBpjsTK, setPotonganBpjsTK] = useState(data.bpjsTK);
  const [potonganBpjsK, setPotonganBpjsK] = useState(data.bpjsK);
  const [lebihBayar, setLebihBayar] = useState(data.lebihBayar);
  const [uangShift, setUangShift] = useState(data.shiftSatu);
  const [lemburLibur, setLemburLibur] = useState(data.lemburLibur);
  const [uangMakan, setUangMakan] = useState(data.uangMakan);
  const [uangTransport, setUangTransport] = useState(data.uangTransport);
  const [cicilanKasbon, setCicilanKasbon] = useState(data.kasbon);
  const [tidakMasuk, setTidakMasuk] = useState(data.tidakMasuk);
  const [jamKerja, setJamKerja] = useState(data.totalWorking / 3600);
  const [lembur, setLembur] = useState(data.jamOT);
  const [kontrakAwal, setKontrakAwal] = useState(data.kontrakAwal);
  const [setengahHari, setSetengahHari] = useState(data.setengahHari / 3600);
  const [confirmation, setConfirmation] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [kepalaShift, setKepalaShift] = useState("");
  const [statusProbation, setStatusProbation] = useState(data.probation);
  const [keteranganKurangBayar, setKeteranganKurangBayar] = useState(
    data.keteranganKurangBayar
  );
  const [keteranganLebihBayar, setKeteranganLebihBayar] = useState(
    data.keteranganLebihBayar
  );
  const [lemburWaktuLibur, setLemburWaktuLibur] = useState(data.jamOTLibur);
  const [jamLebih, setJamLebih] = useState(data.jamLebih);

  const timeToSecond = (data) => {
    let hour = Number(data);
    return hour * 3600;
  };

  const validateData =
    jsonRole.tim === 1 ? kepalaShiftTimOne : kepalaShiftTimTwo;

  const handleCancel = (data) => {
    setNoNPWP(data.npwpNumber);
    setNoRekening(data.bankAccountNumber);
    setNama(data.name);
    setEmail(data.email);
    setTelepon(data.phoneNumber);
    setBagian(data.bagian);
    setAlamat(data.alamat);
    setValueEmployeeType(data.employeeType);
    setJumlahAnak(data.jumlahAnak);
    setStatusPerkawinan(data.statusPerkawinan);
    setShift(data.shift);
    setRole(data.role);
    setPosisi(data.position);
    setTempatTanggalLahir(data.tempatTanggalLahir);
    setLemburLibur(data.lemburLibur);
    setUangShift(data.shiftSatu);
    setKontrakAwal(data.kontrakAwal);
    setKepalaShift(data.kepalaShift);
    setStatusProbation(data.probation);

    setGajiPokok(data.basicSallary);
    setKurangBayar(data.kurangBayar);
    setPotonganBpjsTK(data.bpjsTK);
    setPotonganBpjsK(data.bpjsK);
    setLebihBayar(data.lebihBayar);
    setUangMakan(data.uangMakan);
    setUangTransport(data.uangTransport);
    setCicilanKasbon(data.kasbon);
    setTidakMasuk(data.tidakMasuk);
    setLembur(data.jamOT);
    setSetengahHari(data.setengahHari / 3600);
    setJamKerja(data.totalWorking / 3600);
    setKeteranganKurangBayar(data.keteranganKurangBayar);
    setKeteranganLebihBayar(data.keteranganLebihBayar);
    setLemburWaktuLibur(data.jamOTLibur);
    setJamLebih(data.jamLebih);

    setInitialExpand(false);
  };

  useEffect(() => {
    handleCancel(data);
  }, [id]);

  const handleEditUser = async () => {
    if (editType === "Data Diri") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/updateUser`,
          {
            id: id,
            type: "editUser",
            part: "dataDiri",
            npwpNumber: noNPWP,
            bankAccountNumber: noRekening,
            name: nama,
            email: email,
            phoneNumber: telepon,
            bagian: bagian,
            alamat: alamat,
            employeeType: valueEmployeeType,
            jumlahAnak: Number(jumlahAnak),
            statusPerkawinan: statusPerkawinan,
            shift: Number(shift),
            role: role,
            tempatTanggalLahir: tempatTanggalLahir,
            position: posisi,
            kontrakAwal: kontrakAwal,
            kepalaShift: kepalaShift,
            probation: statusProbation,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken}`,
            },
          }
        );
        if (response.data.data.code === 200) {
          handleCancel(response.data.value);
        }
      } catch (error) {
        return error;
      }
    } else if (editType === "Data Keuangan") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/updateUser`,
          {
            id: id,
            type: "editUser",
            part: "dataKeuangan",
            basicSallary: Number(gajiPokok),
            kurangBayar: Number(kurangBayar),
            bpjsTK: Number(potonganBpjsTK),
            bpjsK: Number(potonganBpjsK),
            lebihBayar: Number(lebihBayar),
            uangMakan: Number(uangMakan),
            uangTransport: Number(uangTransport),
            kasbon: Number(cicilanKasbon),
            totalWorking: timeToSecond(jamKerja),
            totalOT: timeToSecond(lembur),
            setengahHari: timeToSecond(setengahHari),
            shiftSatu: Number(uangShift),
            lemburLibur: Number(lemburLibur),
            keteranganKurangBayar: keteranganKurangBayar,
            keteranganLebihBayar: keteranganLebihBayar,
            jamOTLibur: Number(lemburWaktuLibur),
            jamLebih: Number(jamLebih),
            tidakMasuk: Number(tidakMasuk),
          },
          {
            headers: {
              Authorization: `Bearer ${getToken}`,
            },
          }
        );
        if (response.data.data.code === 200) {
          handleCancel(response.data.value);
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleAktivasi = async (data, status) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/updateUser`,
        {
          type: "aktivasi",
          status: status,
          id: data,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        setConfirmation(false);
      }
    } catch (error) {
      return error;
    }
  };

  const handleConfirmation = (data) => {
    setConfirmation(true);
    setStatusMessage(data);
  };

  return (
    <>
      {confirmation ? (
        <div className="container_popup">
          <div
            className="wrapper_popup"
            style={{
              width: "320px",
            }}
          >
            <p>Apakah Kamu Yakin untuk me- {statusMessage} akun ini?</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                className="cancel_button"
                style={{ margin: "0 12px 0 0" }}
                onClick={() => setConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="reset_button"
                style={{ background: "dodgerblue", margin: 0 }}
                onClick={() => handleAktivasi(data.id, statusMessage)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <tr style={{ cursor: "pointer" }}>
        {jsonRole.role === "hrd_admin" || jsonRole.role === "super_user" ? (
          <td style={{ padding: "12px 0 0px 0px" }}>
            <input
              style={{
                height: "18px",
                width: "18px",
                margin: "2px 3px 3px 4px",
              }}
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setPeopleInfo([
                    ...peopleInfo,
                    {
                      id: data.id,
                    },
                  ]);
                } else {
                  setPeopleInfo(
                    peopleInfo.filter((people) => people.id !== data.id)
                  );
                }
              }}
              value={peopleInfo}
            />
          </td>
        ) : (
          ""
        )}
        <td style={{ maxWidth: "24px", padding: "12px 0 12px 6px" }}>
          {index + 1}.
        </td>

        <td
          onClick={() => setInitialExpand(!initialExpand)}
          style={{ width: "40%", padding: "12px 0 12px 12px" }}
        >
          <div style={{ display: "flex" }}>
            {data.probation ? (
              <span style={{ color: "#FF5C82" }}>&#9733;&nbsp;</span>
            ) : (
              ""
            )}
            <span>{data.name}</span>
          </div>
        </td>
        <td
          onClick={() => setInitialExpand(!initialExpand)}
          style={{
            textAlign: "center",
            width: "25%",
            padding: "12px 0px 12px 6px",
          }}
        >
          {data.position}
        </td>
        <td
          onClick={() => setInitialExpand(!initialExpand)}
          style={
            data.deviceId === "deactivate"
              ? {
                  color: "red",
                  width: "25%",
                  textAlign: "center",
                  padding: "12px 0",
                }
              : {
                  color: "green",
                  width: "25%",
                  textAlign: "center",
                }
          }
        >
          {data.deviceId === "deactivate" ? "Habis Kontrak" : "Aktif"}
        </td>
        <td
          onClick={() => setInitialExpand(!initialExpand)}
          style={{ width: "15%", textAlign: "center" }}
        >
          {data.employeeType}
        </td>
        <td
          style={{
            width: "240px",
            padding: "12px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <button
              className="reset_button"
              style={{
                background: "lightgreen",
                margin: "0 6px 0 0",
                color: "black",
              }}
              onClick={() => handleConfirmation("Aktivasi")}
            >
              Activate
            </button>
            <button
              className="reset_button"
              style={{
                background: "#FEC260",
                margin: "0 6px 0 0",
                color: "black",
              }}
              onClick={() => handleConfirmation("Deaktivasi")}
            >
              Deactivate
            </button>
            <button
              className="reset_button"
              style={{ background: "red", margin: "0 6px 0 0" }}
              onClick={() => handleConfirmation("Delete")}
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
      {initialExpand !== true ? (
        ""
      ) : editType === "Data Diri" ? (
        <div className="container_popup">
          <div className="wrapper_popup">
            <div className="flex_space_between">
              <div>
                <p>No. KTP</p>
                <input
                  className="input_box"
                  type="text"
                  value={data.id}
                  disabled
                />
              </div>
              <div>
                <p>No. NPWP</p>
                <input
                  className="input_box"
                  type="text"
                  value={noNPWP}
                  onChange={(e) => setNoNPWP(e.target.value)}
                />
              </div>

              <div>
                <p>No. Rekening</p>
                <input
                  className="input_box"
                  type="text"
                  value={noRekening}
                  onChange={(e) => setNoRekening(e.target.value)}
                />
              </div>
            </div>

            <div className="flex_space_between">
              <div>
                <p>Nama</p>
                <input
                  className="input_box"
                  type="text"
                  value={nama}
                  onChange={(e) => setNama(e.target.value)}
                />
              </div>
              <div>
                <p>Email</p>
                <input
                  className="input_box"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <p>No. Telepon</p>
                <input
                  className="input_box"
                  type="number"
                  value={telepon}
                  onChange={(e) => setTelepon(e.target.value)}
                />
              </div>
            </div>

            <div className="flex_space_between">
              <div>
                <p>Alamat</p>
                <textarea
                  className="text_area"
                  value={alamat}
                  onChange={(e) => setAlamat(e.target.value)}
                />
              </div>
              <div>
                <div>
                  <p>Bagian</p>
                  <select
                    style={{ width: "188px", height: "32px" }}
                    value={bagian}
                    onChange={(e) => setBagian(e.target.value)}
                  >
                    <option value="" selected disabled hidden>
                      Pilih Bagian
                    </option>
                    {BagianConstant.map((data) => (
                      <option value={data.value}>{data.name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <p>Shift</p>
                  <select
                    style={{ width: "188px", height: "32px" }}
                    value={shift}
                    onChange={(e) => setShift(e.target.value)}
                  >
                    <option value="" selected disabled hidden>
                      Pilih Shift
                    </option>
                    <option value="1">Shift 1</option>
                    <option value="2">Shift 2</option>
                    <option value="3">Shift 3</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex_space_between">
              <div style={{ width: "180px" }}>
                <p>Jenis Karyawan</p>
                <div style={{ display: "flex" }}>
                  <div
                    onClick={() => setValueEmployeeType("Permanent")}
                    className="flex_align_center"
                  >
                    <input
                      type="radio"
                      value="Permanent"
                      checked={valueEmployeeType === "Permanent"}
                    />
                    <label style={{ fontSize: "14px" }}>Permanen</label>
                  </div>
                  <div
                    onClick={() => setValueEmployeeType("Contract")}
                    className="flex_align_center"
                  >
                    <input
                      type="radio"
                      value="Contract"
                      checked={valueEmployeeType === "Contract"}
                    />
                    <label style={{ fontSize: "14px" }}>Kontrak</label>
                  </div>
                </div>
              </div>
              <div style={{ width: "180px" }}>
                <p>Status Perkawinan</p>
                <div style={{ display: "flex" }}>
                  <div
                    onClick={() => setStatusPerkawinan("KAWIN")}
                    className="flex_align_center"
                  >
                    <input
                      type="radio"
                      value="KAWIN"
                      checked={statusPerkawinan === "KAWIN"}
                    />
                    <label style={{ fontSize: "14px" }}>Kawin</label>
                  </div>
                  <div
                    onClick={() => setStatusPerkawinan("TIDAK KAWIN")}
                    className="flex_align_center"
                  >
                    <input
                      type="radio"
                      value="TIDAK KAWIN"
                      checked={statusPerkawinan === "TIDAK KAWIN"}
                    />
                    <label style={{ fontSize: "14px" }}>Tidak Kawin</label>
                  </div>
                </div>
              </div>
              <div>
                <p>Jumlah Anak</p>
                <input
                  className="input_box"
                  type="number"
                  value={jumlahAnak}
                  onChange={(e) => setJumlahAnak(e.target.value)}
                />
              </div>
            </div>

            <div className="flex_space_between">
              <div>
                <p>Tempat Tanggal Lahir</p>
                <input
                  className="input_box"
                  type="text"
                  value={tempatTanggalLahir}
                  onChange={(e) => setTempatTanggalLahir(e.target.value)}
                />
              </div>
              <div>
                <p>Role</p>
                <select
                  style={{ width: "188px", height: "32px" }}
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    Pilih Bagian
                  </option>
                  {RoleConstant.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </div>
              <div>
                <p>Posisi</p>
                <input
                  className="input_box"
                  type="text"
                  value={posisi}
                  onChange={(e) => setPosisi(e.target.value)}
                />
              </div>
            </div>
            <div className="flex_space_between">
              <div>
                <p>Kontrak Awal</p>
                <input
                  className="input_box"
                  type="text"
                  value={kontrakAwal}
                  onChange={(e) => setKontrakAwal(e.target.value)}
                />
              </div>
              <div>
                <p>Kepala Shift</p>
                <select
                  style={{ width: "188px", height: "32px" }}
                  value={kepalaShift}
                  onChange={(e) => setKepalaShift(e.target.value)}
                >
                  <option value="" selected disabled>
                    Pilih Kepala
                  </option>
                  {validateData.map((data) => (
                    <option value={data.value}>{data.name}</option>
                  ))}
                </select>
              </div>
              <div style={{ width: "180px" }}>
                <p>Status Percobaan</p>
                <div style={{ display: "flex" }}>
                  <div
                    onClick={() => setStatusProbation(true)}
                    className="flex_align_center"
                    style={{ margin: "6px" }}
                  >
                    <input
                      type="radio"
                      value="YA"
                      checked={statusProbation === true}
                    />
                    <label style={{ fontSize: "14px" }}>YA</label>
                  </div>

                  <div
                    onClick={() => setStatusProbation(false)}
                    className="flex_align_center"
                    style={{ margin: "6px" }}
                  >
                    <input
                      type="radio"
                      value="TIDAK"
                      checked={statusProbation === false}
                    />
                    <label style={{ fontSize: "14px" }}>TIDAK</label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex_space_between"
              style={{ justifyContent: "flex-end" }}
            >
              <div className="container_button">
                <button
                  className="cancel_button"
                  onClick={() => handleCancel(data)}
                >
                  Cancel
                </button>
                <button
                  className="save_button"
                  onClick={() => handleEditUser(data)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : editType === "Data Keuangan" ? (
        <div className="container_popup">
          <div className="wrapper_popup">
            <p
              style={{ fontSize: "16px", fontWeight: "bold", marginTop: "0px" }}
            >
              {data.name}
            </p>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>Pemasukan</p>
            <div className="flex_space_between">
              <div>
                <p>No. KTP</p>
                <input
                  className="input_box"
                  type="text"
                  value={data.id}
                  disabled
                />
              </div>
              <div>
                <p>Gaji Pokok</p>
                <input
                  value={gajiPokok}
                  onChange={(e) => setGajiPokok(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
              <div>
                <p>Shift 1 (Hari)</p>
                <input
                  value={uangShift}
                  onChange={(e) => setUangShift(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
            </div>
            <div className="flex_space_between"></div>
            <div className="flex_space_between">
              <div>
                <p>Uang Makan</p>
                <input
                  value={uangMakan}
                  onChange={(e) => setUangMakan(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
              <div>
                <p>Uang Transport</p>
                <input
                  value={uangTransport}
                  onChange={(e) => setUangTransport(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
              <div>
                <p>Kurang Bayar</p>
                <input
                  value={kurangBayar}
                  onChange={(e) => setKurangBayar(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
            </div>
            <div className="flex_space_between">
              <div>
                <p>Lembur Libur (Hari)</p>
                <input
                  value={lemburLibur}
                  onChange={(e) => setLemburLibur(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
              <div>
                <p>Lembur (Jam)</p>
                <input
                  value={lembur}
                  onChange={(e) => setLembur(Number(e.target.value))}
                  className="input_box"
                  type="number"
                />
              </div>
              <div>
                <p>Keterangan Kurang Bayar</p>
                <input
                  value={keteranganKurangBayar}
                  onChange={(e) => setKeteranganKurangBayar(e.target.value)}
                  className="input_box"
                  type="text"
                />
              </div>
            </div>
            <div className="flex_space_between">
              <div>
                <p>Jam Kerja (Jam)</p>
                <input
                  value={jamKerja}
                  onChange={(e) => setJamKerja(Number(e.target.value))}
                  className="input_box"
                  type="number"
                />
              </div>
              <div>
                <p>Setengah Hari (Jam)</p>
                <input
                  value={setengahHari}
                  onChange={(e) => setSetengahHari(Number(e.target.value))}
                  className="input_box"
                  type="number"
                />
              </div>
              <div>
                <p>Lembur Waktu Libur (Jam)</p>
                <input
                  value={lemburWaktuLibur}
                  onChange={(e) => setLemburWaktuLibur(Number(e.target.value))}
                  className="input_box"
                  type="number"
                />
              </div>
            </div>
            <div className="flex_space_between">
              <div>
                <p>Jam Lebih</p>
                <input
                  value={jamLebih}
                  onChange={(e) => setJamLebih(Number(e.target.value))}
                  className="input_box"
                  type="number"
                />
              </div>
            </div>
          </div>
          <div className="wrapper_popup">
            <p
              style={{ fontSize: "16px", fontWeight: "bold", marginTop: "0px" }}
            >
              Pengeluaran
            </p>
            <div className="flex_space_between">
              <div>
                <p>Cicilan Kasbon</p>
                <input
                  value={cicilanKasbon}
                  onChange={(e) => setCicilanKasbon(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
              <div>
                <p>Lebih Bayar</p>
                <input
                  value={lebihBayar}
                  onChange={(e) => setLebihBayar(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
              <div>
                <p>BPJS Tenaga Kerja</p>
                <input
                  value={potonganBpjsTK}
                  onChange={(e) => setPotonganBpjsTK(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
            </div>
            <div className="flex_space_between">
              <div>
                <p>BPJS Kesehatan</p>
                <input
                  value={potonganBpjsK}
                  onChange={(e) => setPotonganBpjsK(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
              <div>
                <p>Keterangan Lebih Bayar</p>
                <input
                  value={keteranganLebihBayar}
                  onChange={(e) => setKeteranganLebihBayar(e.target.value)}
                  className="input_box"
                  type="text"
                />
              </div>
              <div>
                <p>Tidak Masuk (Jam)</p>
                <input
                  value={tidakMasuk}
                  onChange={(e) => setTidakMasuk(e.target.value)}
                  className="input_box"
                  type="number"
                />
              </div>
            </div>
            <div className="flex_space_between">
              <div></div>
              <div></div>
              <div className="container_button">
                <button
                  className="cancel_button"
                  onClick={() => handleCancel(data)}
                >
                  Cancel
                </button>
                <button
                  className="save_button"
                  onClick={() => handleEditUser(data)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AccordionEditUser;
