import { useState } from "react";
import axios from "axios";

export const useLogin = () => {
  const [valueUsername, setValueUsername] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [statusCode, setStatusCode] = useState("");

  const handleLogin = async () => {
    if (valueUsername !== "" && valuePassword !== "") {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/login`,
          {
            username: valueUsername,
            password: valuePassword,
            platform: "web",
          }
        );
        if (data.code === 200) {
          localStorage.setItem("auth", data.auth);
          localStorage.setItem(
            "user",
            JSON.stringify({
              id: data.id,
              role: data.role,
              tim: data.tim,
              name: data.name,
            })
          );
          setStatusCode(data.code);
        }
      } catch (error) {
        return error;
      }
    }
  };
  return {
    setValueUsername,
    setValuePassword,
    handleLogin,
    statusCode,
  };
};
