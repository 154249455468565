const DropDown = (props) => {
  const { setFilter, dataOption, placeHolder, value, hidden } = props;

  return (
    <select
      style={{
        marginBottom: "18px",
        marginLeft: "12px",
        cursor: "pointer",
        width: "98px",
      }}
      onChange={(e) => setFilter(e.target.value)}
    >
      <option value={value} selected disabled hidden={hidden}>
        {placeHolder}
      </option>
      {dataOption.map((data) => (
        <option value={data.value}>{data.name}</option>
      ))}
    </select>
  );
};
export default DropDown;
