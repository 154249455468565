import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { today } from "../../util/formatDate";

const PatrolSecurity = () => {
  const getToken = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;

  const getTim = localStorage.getItem("user");
  const jsonTim = JSON.parse(getTim);

  const [statusCode, setStatusCode] = useState();
  const [dataPatrol, setDataPatrol] = useState();
  const [dateFilter, setDateFilter] = useState(today);

  const getTableData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/patrolSecurity`,
        {
          tim: jsonTim.tim,
          date: moment(dateFilter).format("ddd MMM DD YYYY"),
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.data.code === 404) {
        setStatusCode(
          response.data.code !== undefined ? response.data.code : ""
        );
      } else {
        setDataPatrol(response.data.data);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getTableData();
  }, [dateFilter]);

  return (
    <div>
      <div>
        <p style={{ fontWeight: "bold" }}>Patrol Security</p>
        <br />
        <input
          style={{ height: "27px", width: "112px" }}
          type="date"
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
        />
      </div>
      <br />
      <div style={{ width: "0px", minWidth: "100%", overflow: "auto" }}>
        <table className="table_dashboard">
          <tr>
            <th>No.</th>
            <th>Tanggal</th>
            <th>Waktu</th>
            <th>Tempat</th>
            <th>Pembuat</th>
          </tr>
          {dataPatrol &&
            dataPatrol.map((e, index) => (
              <tr style={{ cursor: "pointer" }}>
                <div style={{ padding: "12px" }}>{index + 1}</div>
                <td>{e.date}</td>
                <td>{e.time}</td>
                <td> {e.place.replaceAll("_", " ")}</td>
                <td>{e.creator}</td>
              </tr>
            ))}
        </table>
      </div>
    </div>
  );
};
export default PatrolSecurity;
