import moment from "moment";

const dayFormat = ["Ming", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"];
export const dayDefault = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const monthFormat = [
  "Januari",
  "Febuari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];
export const monthDefaultFormat = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const formatDate = (absent, type) => {
  const d = new Date(`${absent.clockIn.day} ${absent.clockIn.time} GMT+0700`);
  const out = new Date(
    `${absent.clockOut.day} ${absent.clockOut.time} GMT+0700`
  );
  let day = d.getDay();
  let date = d.getDate();
  let month = d.getMonth();
  let year = d.getFullYear();
  const fullDate = `${dayFormat[day]} ${date} ${monthDefaultFormat[month]} ${year}`;
  let outDay = out.getDay();
  let outDate = out.getDate();
  let outMonth = out.getMonth();
  let outYear = out.getFullYear();
  const outFulldate =
    absent.clockOut.day !== null
      ? `${dayFormat[outDay]} ${outDate} ${monthDefaultFormat[outMonth]} ${outYear}`
      : "";

  if (type === "clockIn") {
    return d;
  } else if (type === "clockOut") {
    return out;
  } else if (type === "fullDate") {
    return fullDate;
  } else if (type === "outFulldate") {
    return outFulldate;
  }
};
export const validateTime = (absent) => {
  const d = new Date(`${absent.clockIn.day} ${absent.clockIn.time} GMT+0700`);
  const hour = d.getHours();
  const minute = d.getMinutes();
  if (
    (hour <= 7 && minute <= 50 && hour >= 7 && minute >= 10) ||
    (hour <= 5 && minute <= 50 && hour >= 5 && minute >= 10) ||
    (hour <= 21 && minute <= 50 && hour >= 21 && minute >= 10) ||
    (hour <= 13 && minute <= 50 && hour >= 13 && minute >= 10)
  ) {
    return "tepat";
  } else {
    return "terlambat";
  }
};

export const today = moment().utcOffset("+0700").format("YYYY-MM-DD");
export const todayFormated = moment()
  .utcOffset("+0700")
  // .add(-2, "days")
  .format("ddd MMM DD YYYY");
