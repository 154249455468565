import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const EditAbsent = (props) => {
  const {
    setClockInValueEdit,
    setClockOutValueEdit,
    onChange,
    selected,
    selectedIn,
    onChangeIn,
  } = props;
  return (
    <>
      <div style={{ margin: "0 12px" }}>
        <div style={{ width: "fit-content" }}>
          <div>Tanggal Clock In</div>
          <DatePicker selected={selectedIn} onChange={onChangeIn} />
        </div>
        <p
          style={{
            margin: "0 6px 0 0px",
          }}
        >
          Clock In
        </p>
        <input
          type="time"
          style={{
            width: "96px",
            margin: "0 6px 0 0",
            height: "18px",
          }}
          onChange={(e) => setClockInValueEdit(e.target.value)}
        />
      </div>
      <div style={{ margin: "0 12px" }}>
        <div style={{ width: "fit-content" }}>
          <div>Tanggal ClockOut</div>
          <DatePicker selected={selected} onChange={onChange} />
        </div>
        <div>
          <p
            style={{
              margin: "0 6px 0 0px",
            }}
          >
            Clock Out
          </p>
          <input
            type="time"
            style={{
              width: "96px",
              margin: "0 6px 0 0",
              height: "18px",
            }}
            onChange={(e) => setClockOutValueEdit(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};
export default EditAbsent;
