import React, { useState } from "react";
import "./AccordionPayroll.css";
import { monthDefaultFormat, todayFormated } from "../../util/formatDate";

const Accordion = ({
  data,
  uangMakanTransport,
  uangBpjs,
  index,
  type,
  filterDate,
  whitelistTanggal,
}) => {
  const bulanBayar = () => {
    if (data.date) {
      const d = new Date(data.date);
      d.setMonth(d.getMonth() + 1);
      const month = d.getMonth();
      const year = d.getFullYear();
      return `${monthDefaultFormat[month]} ${year}`;
    } else {
      const d = new Date();
      d.setMonth(d.getMonth() + 1);
      const month = d.getMonth();
      const year = d.getFullYear();
      return `${monthDefaultFormat[month]} ${year}`;
    }
  };

  const validateWorkingShiftOne = () => {
    const validateWhiteListTanggal =
      whitelistTanggal &&
      whitelistTanggal.find((o) => o.tanggal === todayFormated);
    if (type === "Payroll") {
      const today = new Date();
      const whitelistDay = today.getDay();
      const yesterday = new Date(`${data.shiftOneDate} 00:00:00 GMT+0700`);
      today.setDate(today.getDate() - 1);

      if (
        yesterday.toDateString() === today.toDateString() &&
        whitelistDay !== 7 &&
        validateWhiteListTanggal === undefined
      ) {
        return data.workingShiftOne;
      } else {
        return 0;
      }
    } else if (type === "Riwayat") {
      const today = new Date(filterDate);
      const whitelistDay = today.getDay();
      const yesterday = new Date(`${data.shiftOneDate} 00:00:00 GMT+0700`);
      today.setDate(today.getDate() - 1);

      if (
        yesterday.toDateString() === today.toDateString() &&
        whitelistDay !== 0 &&
        validateWhiteListTanggal === undefined
      ) {
        return data.workingShiftOne;
      } else {
        return 0;
      }
    }
  };

  const d = new Date(data.date);
  const date = d.getDate();

  const [initialExpand, setInitialExpand] = useState(false);

  const totalWorking = () => {
    if (type === "Payroll") {
      if (data.employeeType === "Permanent") {
        if (data.totalWorking > 730800) {
          return 756000;
        } else {
          return data.totalWorking;
        }
      } else if (data.employeeType === "Contract") {
        if (
          date === 26 &&
          data.totalWorking - validateWorkingShiftOne() > 327600
        ) {
          return 327600;
        } else {
          return data.totalWorking - validateWorkingShiftOne();
        }
      }
    } else if (type === "Riwayat") {
      if (data.employeeType === "Permanent") {
        if (data.totalWorking > 730800) {
          return 756000;
        } else {
          return data.totalWorking;
        }
      } else if (data.employeeType === "Contract") {
        if (
          date === 26 &&
          data.totalWorking - validateWorkingShiftOne() > 327600
        ) {
          return 327600;
        } else {
          return data.totalWorking - validateWorkingShiftOne();
        }
      } else {
        return data.totalWorking - validateWorkingShiftOne();
      }
    }
  };

  const basicSallary = data.basicSallary;
  const daySallary = data.basicSallary / 30;
  const halfDaySallary =
    data.employeeType === "Permanent" &&
    data.setengahHari > 0 &&
    data.setengahHari / 3600 < 7
      ? data.setengahHari / 3600 - 7
      : data.employeeType === "Permanent" && data.setengahHari / 3600 >= 7
      ? data.setengahHari / 3600 - 14
      : data.setengahHari / 3600;

  const tidakMasuk = data.tidakMasuk ? data.tidakMasuk : 0;
  const shiftOne = data.shiftSatu;
  const lemburLibur = data.type === "history_permanent" ? 0 : data.lemburLibur;
  const lembur = data.totalOT;
  const jamOTLibur = data.jamOTLibur ? data.jamOTLibur : 0;
  const displayJamOT = data.jamOT ? data.jamOT : 0;
  const uangTransport = () => {
    if (type === "Riwayat") {
      return data.uangTransport;
    } else {
      if (data.employeeType === "Permanent") {
        return data.uangTransport;
      } else if (data.employeeType === "Contract" && uangMakanTransport) {
        return data.uangTransport;
      } else {
        return 0;
      }
    }
  };
  const uangMakan = () => {
    if (type === "Riwayat") {
      return data.uangMakan;
    } else {
      if (data.employeeType === "Permanent") {
        return data.uangMakan;
      } else if (data.employeeType === "Contract" && uangMakanTransport) {
        return data.uangMakan;
      } else {
        return 0;
      }
    }
  };
  const kurangBayar = data.kurangBayar;
  const lebihBayar = data.lebihBayar;
  const bpjsTK = () => {
    if (type === "Riwayat") {
      return data.bpjsTK;
    } else {
      if (data.employeeType === "Permanent") {
        return data.bpjsTK;
      } else if (data.employeeType === "Contract" && uangBpjs) {
        return data.bpjsTK;
      } else {
        return 0;
      }
    }
  };
  // const bpjsTK = () => {
  //   if (type === "Riwayat") {
  //     return data.bpjsTK;
  //   } else {
  //     if (
  //       data.employeeType === "Permanent" &&
  //       (date === 28 || date === 29 || date === 30 || date === 31)
  //     ) {
  //       return data.bpjsTK;
  //     } else if (data.employeeType === "Contract" && uangBpjs) {
  //       return data.bpjsTK;
  //     } else {
  //       return 0;
  //     }
  //   }
  // };
  // const bpjsK = () => {
  //   if (type === "Riwayat") {
  //     return data.bpjsK;
  //   } else {
  //     if (
  //       data.employeeType === "Permanent" &&
  //       (date === 28 || date === 29 || date === 30 || date === 31)
  //     ) {
  //       return data.bpjsK;
  //     } else if (data.employeeType === "Contract" && uangBpjs) {
  //       return data.bpjsK;
  //     } else {
  //       return 0;
  //     }
  //   }
  // };
  const bpjsK = () => {
    if (type === "Riwayat") {
      return data.bpjsK;
    } else {
      if (data.employeeType === "Permanent") {
        return data.bpjsK;
      } else if (data.employeeType === "Contract" && uangBpjs) {
        return data.bpjsK;
      } else {
        return 0;
      }
    }
  };
  const kasbon = data.kasbon;

  // const kasbon =
  //   data.employeeType === "Permanent" &&
  //   (date === 28 || date === 29 || date === 30 || date === 31)
  //     ? data.kasbon
  //     : data.employeeType === "Contract"
  //     ? data.kasbon
  //     : 0;
  const waktuCicilan = data.waktuCicilan;

  const formatDot = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const potonganTidakMasuk = (tidakMasuk / 7) * daySallary;

  const totalGaji =
    data.employeeType === "Permanent"
      ? data.type === "history_contract"
        ? 0
        : basicSallary
      : daySallary * (totalWorking() / 25200);
  const totalGajiSetengahHari = (daySallary / 7) * halfDaySallary;

  const totalUangShiftSatu =
    data.type === "history_permanent" ? 0 : shiftOne * 4000;
  const totalLemburLibur =
    data.type === "history_permanent" ? 0 : daySallary * 2 * lemburLibur;

  const totalOvertime = (daySallary / 7) * lembur;
  const totalOTLibur = jamOTLibur * (daySallary / 7) * 2;
  const totalSebelumPotongan =
    totalGaji +
    totalGajiSetengahHari +
    totalUangShiftSatu +
    totalLemburLibur +
    totalOvertime +
    totalOTLibur +
    uangTransport() +
    uangMakan() +
    kurangBayar -
    lebihBayar -
    kasbon -
    potonganTidakMasuk;
  const takeHomePay = totalSebelumPotongan - bpjsTK() - bpjsK();

  return (
    <>
      <tr
        style={{ cursor: "pointer" }}
        onClick={() => setInitialExpand(!initialExpand)}
      >
        <div style={{ padding: "12px" }}>{index + 1}</div>
        <td>{data.name}</td>
        <td>{data.position}</td>
        <td>{data.employeeType}</td>
      </tr>
      {initialExpand !== true ? (
        ""
      ) : (
        <>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="top_left">Gaji</td>
            <td className="border_top">
              {data.employeeType === "Permanent" ? (
                <>Rp {formatDot(basicSallary)}</>
              ) : (
                <>
                  {Math.round((totalWorking() / 25200) * 100) / 100} Hari X Rp
                  {formatDot(Math.round(daySallary))}
                </>
              )}
            </td>
            <td className="top_right">
              =&nbsp;&nbsp;
              <span style={{ color: "#3ebd81" }}>
                Rp {formatDot(Math.round(totalGaji))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">Gaji Setengah Hari</td>
            <td style={{ fontSize: "14px" }}>
              {halfDaySallary < 0
                ? halfDaySallary.toFixed(1) * -1
                : halfDaySallary.toFixed(1)}{" "}
              Jam X Rp {formatDot(Math.round(daySallary / 7))}
            </td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span
                style={
                  halfDaySallary < 0 ? { color: "red" } : { color: "#3ebd81" }
                }
              >
                Rp{" "}
                {halfDaySallary < 0
                  ? formatDot(Math.round(totalGajiSetengahHari * -1))
                  : formatDot(Math.round(totalGajiSetengahHari))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">Uang Shift 1</td>
            <td style={{ fontSize: "14px" }}>{shiftOne} Hari X Rp 4.000</td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "#3ebd81" }}>
                Rp {formatDot(totalUangShiftSatu)}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">Lembur Libur</td>
            <td style={{ fontSize: "14px" }}>
              {lemburLibur} Hari X Rp {formatDot(Math.round(daySallary * 2))}
            </td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "#3ebd81" }}>
                Rp {formatDot(Math.round(totalLemburLibur))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">Lebih Waktu</td>
            <td style={{ fontSize: "14px" }}>
              {displayJamOT} Jam X Rp {formatDot(Math.round(daySallary / 7))}
            </td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "#3ebd81" }}>
                Rp {formatDot(Math.round(totalOvertime))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">Lembur Waktu Libur</td>
            <td style={{ fontSize: "14px" }}>
              {jamOTLibur} Jam X Rp{" "}
              {formatDot(Math.round((daySallary / 7) * 2))}
            </td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "#3ebd81" }}>
                Rp {formatDot(Math.round(totalOTLibur))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">Uang Transport</td>
            <td></td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "#3ebd81" }}>
                Rp {formatDot(uangTransport())}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">Uang Makan</td>
            <td></td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "#3ebd81" }}>
                Rp {formatDot(uangMakan())}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">Potongan Kasbon ({waktuCicilan})</td>
            <td></td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "red" }}>
                Rp {formatDot(Math.round(kasbon))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">
              Kurang Bayar (
              {data.keteranganKurangBayar ? data.keteranganKurangBayar : ""})
            </td>
            <td></td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "#3ebd81" }}>
                Rp {formatDot(Math.round(kurangBayar))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">
              Lebih Bayar (
              {data.keteranganLebihBayar ? data.keteranganLebihBayar : ""})
            </td>
            <td></td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "red" }}>
                Rp {formatDot(Math.round(lebihBayar))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">Tidak Masuk (Hari)</td>
            <td style={{ fontSize: "14px" }}>
              {(tidakMasuk / 7).toFixed(1)} Hari X Rp{" "}
              {formatDot(Math.round(daySallary))}
            </td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "red" }}>
                Rp {formatDot(Math.round(potonganTidakMasuk))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_top border_left">Total</td>
            <td className="border_top"></td>
            <td className="border_top border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "#3ebd81" }}>
                Rp {formatDot(Math.round(totalSebelumPotongan))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">Potongan</td>
            <td></td>
            <td className="border_right"></td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">&nbsp;&nbsp;BPJSTK ({bulanBayar()})</td>
            <td></td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "red" }}>Rp {formatDot(bpjsTK())}</span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="border_left">&nbsp;&nbsp;BPJSK ({bulanBayar()})</td>
            <td></td>
            <td className="border_right">
              =&nbsp;&nbsp;
              <span style={{ color: "red" }}>Rp {formatDot(bpjsK())}</span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
            <td className="bottom_left border_top">Jumlah Yang Diterima</td>
            <td className="border_bottom border_top"></td>
            <td className="bottom_right border_top">
              =&nbsp;&nbsp;
              <span style={{ color: "#3ebd81" }}>
                Rp {formatDot(Math.round(takeHomePay))}
              </span>
            </td>
          </tr>
        </>
      )}
    </>
  );
};
export default Accordion;
