import { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { monthDefaultFormat, dayDefault } from "../../util/formatDate";

export const useDashboard = () => {
  const getToken = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;
  const getTim = localStorage.getItem("user");
  const jsonTim = JSON.parse(getTim);

  const filterDateYear = new Date();
  const filter = filterDateYear.getFullYear();

  const [dataUser, setDataUser] = useState();
  const [paramSearch, setParamSearch] = useState("name");
  const [valueSearch, setValueSearch] = useState("");
  const [isEditAbsen, setIsEditAbsen] = useState(false);
  const [isEditOverTime, setIsEditOverTime] = useState(false);
  const [initialEdit, setInitialEdit] = useState(false);
  const [initialEditOT, setInitialEditOT] = useState(false);
  const [clockInValueEdit, setClockInValueEdit] = useState("");
  const [clockOutValueEdit, setClockOutValueEdit] = useState("");
  const [statusCode, setStatusCode] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterYear, setFilterYear] = useState(filter);
  const [filterRole, setFilterRole] = useState("");
  const [filterKepala, setFilterKepala] = useState("");
  const [dateClockOut, setDateClockOut] = useState(new Date());
  const [dateClockIn, setDateClockIn] = useState(new Date());
  const [filterDate, setFilterDate] = useState("");
  const [clockIn, setClockIn] = useState("");
  const [dateIn, setDateIn] = useState("");
  const [clockOut, setClockOut] = useState("");
  const [dateOut, setDateOut] = useState("");
  const [ktpNumber, setKtpNumber] = useState("");
  const [filterDateFrom, setFilterDateFrom] = useState("");
  const [filterDateUntil, setFilterDateUntil] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const getDefaultDate = new Date(filterDate);
  let defaultDay = getDefaultDate.getDay();
  let defaultMonth = getDefaultDate.getMonth();
  let defaultDateNumber = getDefaultDate.getDate();
  let year = getDefaultDate.getFullYear();
  const defaultDate = `${dayDefault[defaultDay]} ${monthDefaultFormat[defaultMonth]} ${defaultDateNumber} ${year}`;

  let editParams = [];
  let editOTParams = [];

  const sortByName = (data) =>
    data.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const getTableData = async () => {
    try {
      if (filterMonth !== "") {
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/userList`,
          {
            filterMonth: filterMonth,
            filterYear: filterYear,
            filterRole: filterRole,
            kepalaShift: filterKepala,
            type: "absent",
            filterDate: filterDate === "" ? "" : defaultDate,
            tim: jsonTim.tim,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken}`,
            },
          }
        );
        if (response.data.code === 404) {
          setStatusCode(
            response.data.code !== undefined ? response.data.code : ""
          );
        } else {
          setDataUser(sortByName(response.data));
        }
      }
    } catch (error) {
      return error;
    }
  };

  const handleFilterUntil = async () => {
    try {
      if (filterMonth !== "") {
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/userList`,
          {
            filterDateFrom: filterDateFrom,
            filterDateUntil: filterDateUntil,
            type: "filterUntil",
            tim: jsonTim.tim,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken}`,
            },
          }
        );
        if (response.data.code === 404) {
          setStatusCode(
            response.data.code !== undefined ? response.data.code : ""
          );
        } else {
          setDataUser(sortByName(response.data));
          setIsFilter(false);
        }
      }
    } catch (error) {
      return error;
    }
  };

  const updateUser = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/updateUser`,
        data,
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  const addNewAbsent = () => {
    const addNewDateIn = new Date(dateIn);
    let dayIn = addNewDateIn.getDay();
    let monthIn = addNewDateIn.getMonth();
    let date = addNewDateIn.getDate();
    let yearIn = addNewDateIn.getFullYear();
    const dateClockIn = `${dayDefault[dayIn]} ${monthDefaultFormat[monthIn]} ${date} ${yearIn}`;
    const addNewDateOut = new Date(dateOut);
    let dayOut = addNewDateOut.getDay();
    let monthOut = addNewDateOut.getMonth();
    let dateAddNewOut = addNewDateOut.getDate();
    let yearOut = addNewDateOut.getFullYear();
    const dateClockOut = `${dayDefault[dayOut]} ${monthDefaultFormat[monthOut]} ${dateAddNewOut} ${yearOut}`;
    updateUser({
      id: ktpNumber,
      type: "addNewAbsent",
      tanggalMasuk: dateClockIn,
      jamMasuk: clockIn,
      tanggalKeluar: dateClockOut,
      jamKeluar: clockOut,
    });
    setClockIn("");
    setDateIn("");
    setClockOut("");
    setDateOut("");
  };

  const searchFeature = (params) => {
    if (paramSearch === "name" && valueSearch !== "") {
      let searchName = [];
      dataUser &&
        dataUser.forEach((value) => {
          if (value.name.toLowerCase().includes(params.toLowerCase())) {
            searchName.push(value);
          }
        });
      return searchName;
    } else {
      return dataUser;
    }
  };

  const handleLogout = () => {
    const id = localStorage.getItem("user");
    updateUser({
      id: localStorage.getItem(id.id),
      type: "logout",
    });
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    window.location.replace("/login");
  };

  const handleEdit = (index, type) => {
    if (type === "absent") {
      setIsEditAbsen(true);
      if ((editParams[index] = !editParams[index])) {
        setInitialEdit([...editParams]);
      }
    } else if (type === "overTime") {
      setIsEditOverTime(true);
      if ((editOTParams[index] = !editOTParams[index])) {
        setInitialEditOT([...editOTParams]);
      }
    }
  };

  const handleFormatDateEdit = (data) => {
    const getDefaultDate = new Date(data);
    let defaultDay = getDefaultDate.getDay();
    let defaultMonth = getDefaultDate.getMonth();
    let defaultDateNumber = getDefaultDate.getDate();
    let year = getDefaultDate.getFullYear();
    return `${dayDefault[defaultDay]} ${monthDefaultFormat[defaultMonth]} ${defaultDateNumber} ${year}`;
  };

  const handleKirim = (data, id, type) => {
    const obj = {
      id: id,
      type: type,
      dataEdit: data.clockIn.day,
      absent: {
        clockIn: {
          day: handleFormatDateEdit(dateClockIn),
          time: `${clockInValueEdit}:00`,
        },
        clockOut: {
          day: handleFormatDateEdit(dateClockOut),
          time: `${clockOutValueEdit}:00`,
        },
      },
    };
    if (clockInValueEdit !== "" && clockOutValueEdit !== "") {
      updateUser(obj);
    }
    setClockInValueEdit("");
    setClockOutValueEdit("");
    setIsEditAbsen(false);
    setIsEditOverTime(false);
  };

  const handleExportExcel = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/exportExcel`,
        {
          filterMonth: filterMonth,
          filterRole: filterRole,
          filterYear: filterYear,
          type: "absent",
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      const d = new Date();
      const year = d.getFullYear();

      const sortByNama = response.data.sort(function (a, b) {
        if (a.Nama < b.Nama) {
          return -1;
        }
        if (a.Nama > b.Nama) {
          return 1;
        }
        return 0;
      });

      const workSheet = XLSX.utils.json_to_sheet(sortByNama);
      workSheet["!cols"] = [
        { wch: 17 },
        { wch: 32 },
        { wch: 16 },
        { wch: 22 },
        { wch: 22 },
      ];
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "attendance");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, `absensi ${filterMonth} ${year}.xlsx`);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getTableData();
  }, [filterMonth, filterRole, filterDate, filterYear, filterKepala]);

  return {
    dataUser,
    jsonTim,
    valueSearch,
    setValueSearch,
    setParamSearch,
    searchFeature,
    handleEdit,
    isEditAbsen,
    setIsEditAbsen,
    initialEdit,
    setInitialEdit,
    initialEditOT,
    editParams,
    handleKirim,
    setClockInValueEdit,
    setClockOutValueEdit,
    statusCode,
    handleLogout,
    setFilterMonth,
    filterMonth,
    setFilterRole,
    handleExportExcel,
    editOTParams,
    isEditOverTime,
    setDateClockOut,
    dateClockOut,
    setFilterDate,
    addNewAbsent,
    setClockIn,
    setDateIn,
    setClockOut,
    setDateOut,
    setKtpNumber,
    setDateClockIn,
    dateClockIn,
    isFilter,
    setIsFilter,
    setFilterDateFrom,
    setFilterDateUntil,
    handleFilterUntil,
    setFilterYear,
    filterYear,
    filterKepala,
    setFilterKepala,
  };
};
