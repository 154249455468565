import Search from "../../component/search";
import axios from "axios";
import DropDown from "../../component/DropDown";
import { BagianConstant, jenisKaryawan } from "../../constant";
import * as XLSX from "xlsx";
import {
  monthFormat,
  monthDefaultFormat,
  dayDefault,
  todayFormated,
} from "../../util/formatDate";
import React, { useState, useEffect } from "react";
import Accordion from "../../component/AccordionPayroll/AccordionPayroll";
import "./Payroll.css";

const Payroll = () => {
  const getToken = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;
  const getTim = localStorage.getItem("user");
  const jsonTim = JSON.parse(getTim);

  const [statusCode, setStatusCode] = useState();
  const [dataUser, setDataUser] = useState();
  const [dataHistory, setDataHistory] = useState();
  const [filterRole, setFilterRole] = useState("");
  const [filterJenisKaryawan, setFilterJenisKaryawan] = useState("");
  const [subMenu, setSubMenu] = useState("payroll");
  const [filterBagian, setFilterBagian] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [popupExport, setPopupExport] = useState(false);
  const [valueEmployeeExport, setValueEmployeeExport] = useState("");
  const [whitelistTanggal, setWhitelistTanggal] = useState();

  const today = new Date();
  let getDate = today.getDate();

  const uangMakanTransport = getDate > 24 && getDate <= 31;
  const uangBpjs = getDate > 24 && getDate <= 31;

  const handleGetWhitelistTanggal = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "getTanggalWhitelist",
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setWhitelistTanggal(response.data);
      }
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    handleGetWhitelistTanggal();
  }, []);

  const sortByName = (data) =>
    data.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const getTableData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/userList`,
        {
          type: "payroll",
          filterRole: filterRole,
          tim: jsonTim.tim,
          jenisKaryawan: filterJenisKaryawan,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.data.code === 404) {
        setStatusCode(
          response.data.code !== undefined ? response.data.code : ""
        );
      } else {
        let dataTemp = [];
        response.data.forEach((data) => {
          if (
            data.totalWorking !== 0 ||
            data.totalOT !== 0 ||
            data.shiftSatu !== 0 ||
            data.setengahHari !== 0 ||
            (data.jamOTLibur !== 0 && data.jamOTLibur)
          ) {
            dataTemp.push(data);
          }
        });
        setDataUser(sortByName(dataTemp));
      }
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getTableData();
  }, [filterRole, filterJenisKaryawan]);

  const formatDot = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const [paramSearch, setParamSearch] = useState("nameId");
  const [valueSearch, setValueSearch] = useState("");
  const [valueEmployeeType, setValueEmployeeType] = useState("");
  const [popUp, setPopUp] = useState(false);

  const handleSubMenu = (data) => {
    setSubMenu(data);
    setValueSearch("");
    setShowSearch(false);
  };

  const handleReplicatePayroll = async () => {
    const today = new Date();
    let defaultDay = today.getDay();
    let defaultMonth = today.getMonth();
    let defaultDateNumber = today.getDate();
    let year = today.getFullYear();
    const defaultDate = `${dayDefault[defaultDay]} ${monthDefaultFormat[defaultMonth]} ${defaultDateNumber} ${year}`;

    let dataSorted = [];
    for (let i = 0; i < dataUser.length; i++) {
      const validateWorkingShiftOne = () => {
        const today = new Date();
        const yesterday = new Date(
          `${dataUser[i].shiftOneDate} 00:00:00 GMT+0700`
        );
        today.setDate(today.getDate() - 1);

        if (yesterday.toDateString() === today.toDateString()) {
          return dataUser[i].workingShiftOne;
        } else {
          return 0;
        }
      };

      const totalWorkingReplicate = () => {
        return dataUser[i].totalWorking - validateWorkingShiftOne();
      };
      if (
        valueEmployeeType === "Permanent" &&
        dataUser[i].employeeType === "Permanent"
      ) {
        const data = {
          alamat: dataUser[i].alamat,
          bagian: dataUser[i].bagian,
          bankAccountNumber: dataUser[i].bankAccountNumber,
          basicSallary: dataUser[i].basicSallary,
          bpjsK: dataUser[i].bpjsK,
          bpjsTK: dataUser[i].bpjsTK,
          email: dataUser[i].email,
          employeeType: dataUser[i].employeeType,
          id: dataUser[i].id,
          jamOT: dataUser[i].jamOT,
          jamOTLibur: dataUser[i].jamOTLibur,
          jumlahAnak: dataUser[i].jumlahAnak,
          kasbon: dataUser[i].kasbon,
          kasbonTemp: dataUser[i].kasbonTemp,
          kurangBayar: dataUser[i].kurangBayar,
          lebihBayar: dataUser[i].lebihBayar,
          lemburLibur: dataUser[i].lemburLibur,
          name: dataUser[i].name,
          npwpNumber: dataUser[i].npwpNumber,
          phoneNumber: dataUser[i].phoneNumber,
          position: dataUser[i].position,
          role: dataUser[i].role,
          setengahHari: dataUser[i].setengahHari,
          shift: dataUser[i].shift,
          shiftSatu: dataUser[i].shiftSatu,
          statusPerkawinan: dataUser[i].statusPerkawinan,
          tempatTanggalLahir: dataUser[i].tempatTanggalLahir,
          tim: dataUser[i].tim,
          totalOT: dataUser[i].totalOT,
          totalWorking: dataUser[i].totalWorking,
          uangMakan: uangMakanTransport ? dataUser[i].uangMakan : 0,
          uangTransport: uangMakanTransport ? dataUser[i].uangTransport : 0,
          waktuCicilan: dataUser[i].waktuCicilan,
          shiftOneDate: dataUser[i].shiftOneDate,
          workingShiftOne: 0,
          date: defaultDate,
          keteranganKurangBayar: dataUser[i].keteranganKurangBayar
            ? dataUser[i].keteranganKurangBayar
            : "",
          keteranganLebihBayar: dataUser[i].keteranganLebihBayar
            ? dataUser[i].keteranganLebihBayar
            : "",
          tidakMasuk: dataUser[i].tidakMasuk,
          type: "history_permanent",
        };
        dataSorted.push(data);
      } else if (valueEmployeeType === "Contract") {
        const data = {
          alamat: dataUser[i].alamat,
          bagian: dataUser[i].bagian,
          bankAccountNumber: dataUser[i].bankAccountNumber,
          basicSallary: dataUser[i].basicSallary,
          bpjsK: uangBpjs
            ? dataUser[i].employeeType === "Contract"
              ? dataUser[i].bpjsK
              : 0
            : 0,
          bpjsTK: uangBpjs
            ? dataUser[i].employeeType === "Contract"
              ? dataUser[i].bpjsTK
              : 0
            : 0,
          email: dataUser[i].email,
          employeeType: dataUser[i].employeeType,
          id: dataUser[i].id,
          jamOT: dataUser[i].jamOT,
          jamOTLibur: dataUser[i].jamOTLibur,
          jumlahAnak: dataUser[i].jumlahAnak,
          kasbon: dataUser[i].kasbon,
          kasbonTemp: dataUser[i].kasbonTemp,
          kurangBayar: dataUser[i].kurangBayar,
          lebihBayar: dataUser[i].lebihBayar,
          lemburLibur: dataUser[i].lemburLibur,
          name: dataUser[i].name,
          npwpNumber: dataUser[i].npwpNumber,
          phoneNumber: dataUser[i].phoneNumber,
          position: dataUser[i].position,
          role: dataUser[i].role,
          setengahHari: dataUser[i].setengahHari,
          shift: dataUser[i].shift,
          shiftSatu: dataUser[i].shiftSatu,
          statusPerkawinan: dataUser[i].statusPerkawinan,
          tempatTanggalLahir: dataUser[i].tempatTanggalLahir,
          tim: dataUser[i].tim,
          totalOT: dataUser[i].totalOT,
          totalWorking: totalWorkingReplicate(),
          uangMakan: uangMakanTransport
            ? dataUser[i].employeeType === "Contract"
              ? dataUser[i].uangMakan
              : 0
            : 0,
          uangTransport: uangMakanTransport
            ? dataUser[i].employeeType === "Contract"
              ? dataUser[i].uangTransport
              : 0
            : 0,
          waktuCicilan: dataUser[i].waktuCicilan,
          shiftOneDate: dataUser[i].shiftOneDate,
          workingShiftOne: dataUser[i].workingShiftOne,
          date: defaultDate,
          keteranganKurangBayar: dataUser[i].keteranganKurangBayar
            ? dataUser[i].keteranganKurangBayar
            : "",
          keteranganLebihBayar: dataUser[i].keteranganLebihBayar
            ? dataUser[i].keteranganLebihBayar
            : "",
          type: "history_contract",
        };
        dataSorted.push(data);
      }
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/payrollHistory`,
        {
          type: "replicateHistory",
          data: dataSorted,
          valueEmployeeType: valueEmployeeType,
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      dataSorted = [];
    } catch (error) {
      return error;
    }
  };

  const handleExportPayroll = () => {
    let dataSorted = [];
    for (let i = 0; i < dataUser.length; i++) {
      if (
        valueEmployeeExport === "Permanent" &&
        dataUser[i].employeeType === "Permanent"
      ) {
        const data = {
          alamat: dataUser[i].alamat,
          bagian: dataUser[i].bagian,
          bankAccountNumber: dataUser[i].bankAccountNumber,
          basicSallary: dataUser[i].basicSallary,
          bpjsK: dataUser[i].bpjsK,
          bpjsTK: dataUser[i].bpjsTK,
          email: dataUser[i].email,
          employeeType: dataUser[i].employeeType,
          id: dataUser[i].id,
          jamOT: dataUser[i].jamOT,
          jamOTLibur: dataUser[i].jamOTLibur,
          jumlahAnak: dataUser[i].jumlahAnak,
          kasbon: dataUser[i].kasbon,
          kasbonTemp: dataUser[i].kasbonTemp,
          kurangBayar: dataUser[i].kurangBayar,
          lebihBayar: dataUser[i].lebihBayar,
          lemburLibur: dataUser[i].lemburLibur,
          name: dataUser[i].name,
          npwpNumber: dataUser[i].npwpNumber,
          phoneNumber: dataUser[i].phoneNumber,
          position: dataUser[i].position,
          role: dataUser[i].role,
          setengahHari: dataUser[i].setengahHari,
          shift: dataUser[i].shift,
          shiftSatu: dataUser[i].shiftSatu,
          statusPerkawinan: dataUser[i].statusPerkawinan,
          tempatTanggalLahir: dataUser[i].tempatTanggalLahir,
          tim: dataUser[i].tim,
          totalOT: dataUser[i].totalOT,
          totalWorking: dataUser[i].totalWorking,
          uangMakan: uangMakanTransport ? dataUser[i].uangMakan : 0,
          uangTransport: uangMakanTransport ? dataUser[i].uangTransport : 0,
          waktuCicilan: dataUser[i].waktuCicilan,
          shiftOneDate: dataUser[i].shiftOneDate,
          workingShiftOne: dataUser[i].workingShiftOne,
          tidakMasuk: dataUser[i].tidakMasuk,
          keteranganKurangBayar: dataUser[i].keteranganKurangBayar,
        };
        dataSorted.push(data);
      } else if (valueEmployeeExport === "Contract") {
        const validatePermanent = () => {
          if (dataUser[i].employeeType === "Permanent") {
            return false;
          } else {
            return true;
          }
        };

        const data = {
          alamat: dataUser[i].alamat,
          bagian: dataUser[i].bagian,
          bankAccountNumber: dataUser[i].bankAccountNumber,
          basicSallary: dataUser[i].basicSallary,
          bpjsK: uangBpjs && validatePermanent() ? dataUser[i].bpjsK : 0,
          bpjsTK: uangBpjs && validatePermanent() ? dataUser[i].bpjsTK : 0,
          email: dataUser[i].email,
          employeeType: dataUser[i].employeeType,
          id: dataUser[i].id,
          jamOT: validatePermanent() ? dataUser[i].jamOT : 0,
          jamOTLibur: dataUser[i].jamOTLibur,
          jumlahAnak: dataUser[i].jumlahAnak,
          kasbon: dataUser[i].kasbon,
          kasbonTemp: dataUser[i].kasbonTemp,
          kurangBayar: dataUser[i].kurangBayar,
          lebihBayar: dataUser[i].lebihBayar,
          lemburLibur: dataUser[i].lemburLibur,
          name: dataUser[i].name,
          npwpNumber: dataUser[i].npwpNumber,
          phoneNumber: dataUser[i].phoneNumber,
          position: dataUser[i].position,
          role: dataUser[i].role,
          setengahHari: dataUser[i].setengahHari,
          shift: dataUser[i].shift,
          shiftSatu: dataUser[i].shiftSatu,
          statusPerkawinan: dataUser[i].statusPerkawinan,
          tempatTanggalLahir: dataUser[i].tempatTanggalLahir,
          tim: dataUser[i].tim,
          totalOT: validatePermanent() ? dataUser[i].totalOT : 0,
          totalWorking: dataUser[i].totalWorking,
          uangMakan:
            uangMakanTransport && validatePermanent()
              ? dataUser[i].uangMakan
              : 0,
          uangTransport:
            uangMakanTransport && validatePermanent()
              ? dataUser[i].uangTransport
              : 0,
          waktuCicilan: dataUser[i].waktuCicilan,
          shiftOneDate: dataUser[i].shiftOneDate,
          workingShiftOne: dataUser[i].workingShiftOne,
          keteranganKurangBayar: dataUser[i].keteranganKurangBayar,
        };
        dataSorted.push(data);
      }
    }

    const d = new Date();
    const date = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();
    const day = d.getDay();

    let totalGajiSudahPotong = 0;
    let totalGajiSebelumPotong = 0;
    let totalBPJSTK = 0;
    let totalBPJSK = 0;
    let countBPJSTK = 0;
    let countBPJSK = 0;
    let countKasbon = 0;

    const filteredDataExcel =
      dataSorted &&
      dataSorted.map((obj) => {
        const daySallary = obj.basicSallary / 30;
        const halfDaySallary =
          obj.employeeType === "Permanent" && valueEmployeeExport === "Contract"
            ? 0
            : obj.employeeType === "Contract" &&
              valueEmployeeExport === "Permanent"
            ? 0
            : obj.employeeType === "Permanent" &&
              valueEmployeeExport === "Permanent" &&
              obj.setengahHari > 0 &&
              obj.setengahHari / 3600 < 7
            ? obj.setengahHari / 3600 - 7
            : obj.employeeType === "Permanent" && obj.setengahHari / 3600 >= 7
            ? obj.setengahHari / 3600 - 14
            : obj.setengahHari / 3600;

        const shiftOne =
          valueEmployeeExport === "Permanent"
            ? 0
            : valueEmployeeExport === "Contract"
            ? obj.shiftSatu
            : 0;
        const lemburLibur =
          valueEmployeeExport === "Permanent"
            ? 0
            : valueEmployeeExport === "Contract"
            ? obj.lemburLibur
            : 0;
        const lembur = obj.totalOT;
        const tidakMasuk = obj.tidakMasuk ? obj.tidakMasuk : 0;

        const jamOTLibur = obj.jamOTLibur ? obj.jamOTLibur : 0;
        const displayJamOT = obj.jamOT ? obj.jamOT : 0;
        const totalLembur = jamOTLibur + displayJamOT;
        const uangTransport =
          obj.employeeType === "Permanent"
            ? obj.uangTransport
            : obj.employeeType === "Contract" && uangMakanTransport
            ? obj.uangTransport
            : 0;
        const uangMakan =
          obj.employeeType === "Permanent"
            ? obj.uangMakan
            : obj.employeeType === "Contract" && uangMakanTransport
            ? obj.uangMakan
            : 0;
        const kurangBayar = obj.kurangBayar;
        const lebihBayar = obj.lebihBayar;
        const bpjsTK = () => {
          if (obj.employeeType === "Permanent") {
            return obj.bpjsTK;
          } else if (obj.employeeType === "Contract" && uangBpjs) {
            return obj.bpjsTK;
          } else {
            return 0;
          }
        };
        const bpjsK = () => {
          if (obj.employeeType === "Permanent") {
            return obj.bpjsK;
          } else if (obj.employeeType === "Contract" && uangBpjs) {
            return obj.bpjsK;
          } else {
            return 0;
          }
        };
        const kasbon =
          obj.employeeType === "Permanent"
            ? obj.kasbon
            : obj.employeeType === "Contract"
            ? obj.kasbon
            : 0;

        const kasbonTemp = obj.kasbonTemp;

        const validateWorkingShiftOne = () => {
          const validateWhiteListTanggal =
            whitelistTanggal &&
            whitelistTanggal.find((o) => o.tanggal === todayFormated);

          const today = new Date();
          const whitelistDay = today.getDay();
          const yesterday = new Date(`${obj.shiftOneDate} 00:00:00 GMT+0700`);
          today.setDate(today.getDate() - 1);

          if (
            yesterday.toDateString() === today.toDateString() &&
            whitelistDay !== 0 &&
            validateWhiteListTanggal === undefined
          ) {
            return obj.workingShiftOne;
          } else {
            return 0;
          }
        };

        const hariKerja = () => {
          if (
            obj.employeeType === "Contract" &&
            valueEmployeeExport === "Contract"
          ) {
            return (obj.totalWorking - validateWorkingShiftOne()) / 25200;
          } else if (
            obj.employeeType === "Permanent" &&
            valueEmployeeExport === "Permanent"
          ) {
            return 756000 / 25200;
          } else {
            return 0;
          }
        };

        const totalGaji = daySallary * hariKerja();
        const potonganTidakMasuk = (tidakMasuk / 7) * daySallary;
        const totalGajiSetengahHari = (daySallary / 7) * halfDaySallary;
        const totalUangShiftSatu = shiftOne * 4000;
        const totalLemburLibur = daySallary * 2 * lemburLibur;
        const totalOvertime =
          (daySallary / 7) * lembur + jamOTLibur * (daySallary / 7) * 2;
        const totalSebelumPotongan =
          totalGaji +
          totalGajiSetengahHari +
          totalUangShiftSatu +
          totalLemburLibur +
          totalOvertime +
          uangTransport +
          uangMakan +
          kurangBayar -
          lebihBayar -
          potonganTidakMasuk;

        const totalSallarySudahPotong = Math.round(
          totalSebelumPotongan - bpjsTK() - bpjsK() - kasbon
        );

        const totalSallaryBelumPotong = Math.round(totalSebelumPotongan);

        if (bpjsTK() !== 0) {
          countBPJSTK++;
        }
        if (bpjsK() !== 0) {
          countBPJSK++;
        }
        countKasbon = countKasbon + kasbonTemp;
        totalGajiSudahPotong = totalGajiSudahPotong + totalSallarySudahPotong;
        totalGajiSebelumPotong =
          totalGajiSebelumPotong + totalSallaryBelumPotong;
        totalBPJSK = totalBPJSK + bpjsK();
        totalBPJSTK = totalBPJSTK + bpjsTK();

        return {
          ID: obj.id,
          "No. Rekening": obj.bankAccountNumber,
          Nama: obj.name,
          Gaji: totalSallarySudahPotong,
          "Hari Kerja": hariKerja(),
          "Lembur Hari Minggu": lemburLibur,
          "Lembur Jam": totalLembur,
          Email: obj.email,
          Bagian: obj.bagian,
          "Kurang Bayar": obj.kurangBayar,
          Keterangan: obj.keteranganKurangBayar,
          UM: obj.uangMakan,
          UT: obj.uangTransport,
          "BPJS K": obj.bpjsK,
          "BPJS TK": obj.bpjsTK,
        };
      });

    const sortByNama = filteredDataExcel.sort(function (a, b) {
      if (a.Bagian < b.Bagian) {
        return -1;
      }
      if (a.Bagian > b.Bagian) {
        return 1;
      }
      return 0;
    });

    const Jumlah = [
      {
        ID: "",
        "No. Rekening": "Jumlah",
        Nama: "",
        Gaji: formatDot(totalGajiSudahPotong),
      },
      {
        ID: "",
        "No. Rekening": "Kasbon",
        Nama: "",
        Gaji: formatDot(countKasbon),
      },
      {
        ID: "",
        "No. Rekening": `BPJS Ketenaga Kerjaan (${countBPJSTK} Orang)`,
        Nama: "",
        Gaji: formatDot(totalBPJSTK),
      },
      {
        ID: "",
        "No. Rekening": `BPJS Kesehatan (${countBPJSK} Orang)`,
        Nama: "",
        Gaji: formatDot(totalBPJSK),
      },
      {
        ID: "",
        "No. Rekening": "Total",
        Nama: "",
        Gaji: formatDot(totalGajiSudahPotong),
      },
    ];
    const workSheet = XLSX.utils.json_to_sheet(sortByNama.concat(Jumlah));
    workSheet["!cols"] = [
      { wch: 17 },
      { wch: 14 },
      { wch: 30 },
      { wch: 10 },
      { wch: 10 },
      { wch: 17 },
      { wch: 11 },
      { wch: 35 },
      { wch: 16 },
      { wch: 12 },
      { wch: 35 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
    ];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Gaji Transfer");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, `Payroll ${monthFormat[month]} ${year}.xlsx`);
  };

  const searchFeature = (params) => {
    if (paramSearch === "nameId" && valueSearch !== "") {
      let searchName = [];
      dataUser &&
        dataUser.forEach((value) => {
          if (value.name.toLowerCase().includes(params.toLowerCase())) {
            searchName.push(value);
          }
          if (value.id.includes(params)) {
            searchName.push(value);
          }
        });
      return searchName;
    } else {
      return dataUser;
    }
  };

  const exportHistoryPayroll = () => {
    const d = new Date();
    const month = d.getMonth();
    const year = d.getFullYear();
    const dataFilterExcel =
      dataUser &&
      dataUser.map((data) => {
        const validateWorkingShiftOne = () => {
          const today = new Date();
          const yesterday = new Date(`${data.shiftOneDate} 00:00:00 GMT+0700`);
          today.setDate(today.getDate() - 1);
          if (yesterday.toDateString() === today.toDateString()) {
            return data.workingShiftOne;
          } else {
            return 0;
          }
        };
        const d = new Date();
        const date = d.getDate();
        const day = d.getDay();
        const tidakMasuk = data.tidakMasuk ? data.tidakMasuk : 0;
        const totalWorking = () => {
          if (data.employeeType === "Permanent") {
            return 756000 - tidakMasuk * 3600;
          } else if (data.employeeType === "Contract") {
            return data.totalWorking - validateWorkingShiftOne();
          }
        };
        const daySallary = Math.round(data.basicSallary / 30);
        const halfDaySallary = data.setengahHari / 3600;
        const shiftOne = data.shiftSatu;
        const lemburLibur = data.lemburLibur;
        const lembur = data.totalOT;
        const jamOTLibur = data.jamOTLibur ? data.jamOTLibur : 0;
        const displayJamOT = data.jamOT ? data.jamOT : 0;
        const uangTransport =
          data.employeeType === "Permanent"
            ? data.uangTransport
            : data.employeeType === "Contract" && uangMakanTransport
            ? data.uangTransport
            : 0;
        const uangMakan =
          data.employeeType === "Permanent"
            ? data.uangMakan
            : data.employeeType === "Contract" && uangMakanTransport
            ? data.uangMakan
            : 0;
        const kurangBayar = data.kurangBayar;
        const lebihBayar = data.lebihBayar;
        const bpjsTK =
          data.employeeType === "Permanent"
            ? data.bpjsTK
            : data.employeeType === "Contract" && uangBpjs
            ? data.bpjsTK
            : 0;
        const bpjsK =
          data.employeeType === "Permanent"
            ? data.bpjsK
            : data.employeeType === "Contract" && uangBpjs
            ? data.bpjsK
            : 0;
        const kasbon =
          data.employeeType === "Permanent"
            ? data.kasbon
            : data.employeeType === "Contract"
            ? data.kasbon
            : 0;

        const totalGaji = daySallary * (totalWorking() / 25200);
        const totalGajiSetengahHari = (daySallary / 7) * halfDaySallary;
        const totalUangShiftSatu = shiftOne * 4000;
        const totalLemburLibur = daySallary * 2 * lemburLibur;
        const totalOvertime =
          (daySallary / 7) * lembur + jamOTLibur * (daySallary / 7) * 2;
        const totalSebelumPotongan =
          totalGaji +
          totalGajiSetengahHari +
          totalUangShiftSatu +
          totalLemburLibur +
          totalOvertime +
          uangTransport +
          uangMakan +
          kurangBayar -
          lebihBayar -
          kasbon;
        const takeHomePay = totalSebelumPotongan - bpjsTK - bpjsK;
        return {
          ID: data.id,
          Nama: data.name,
          "Hari Kerja": totalWorking() / 25200,
          Gaji: (daySallary * totalWorking()) / 25200,
          "Gaji Setengah Hari": totalGajiSetengahHari,
          "Uang Shift 1": totalUangShiftSatu,
          "Lembur Libur": lemburLibur,
          "Lebih Waktu": displayJamOT,
          "Lembur Waktu Libur": jamOTLibur,
          "Uang Transport": uangTransport,
          "Uang Makan": uangMakan,
          "Potongan Kasbon": kasbon,
          "Kurang Bayar": kurangBayar,
          "Lebih Bayar": lebihBayar,
          "BPJS TK": bpjsTK,
          "BPJS K": bpjsK,
          Total: takeHomePay,
          "Jam Lebih": data.jamLebih,
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(dataFilterExcel);
    workSheet["!cols"] = [
      { wch: 18 },
      { wch: 35 },
      { wch: 9 },
      { wch: 11 },
      { wch: 16 },
      { wch: 10 },
      { wch: 11 },
      { wch: 10 },
      { wch: 17 },
      { wch: 13 },
      { wch: 11 },
      { wch: 14 },
      { wch: 11 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 9 },
    ];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Riwayat Gaji");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Payroll History ${monthFormat[month]} ${year}.xlsx`
    );
  };

  let dataTemp = searchFeature(valueSearch);

  const handleResetData = async () => {
    if (valueEmployeeType !== "") {
      setPopUp(false);
      handleReplicatePayroll();

      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/payroll`,
          {
            employeeType: valueEmployeeType,
            tim: jsonTim.tim,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken}`,
            },
          }
        );
        if (data.code === 200) {
          window.location.reload();
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleCancel = () => {
    setValueEmployeeType("");
    setPopUp(false);
  };

  const role = localStorage.getItem("user");
  const jsonRole = JSON.parse(role);

  const [showSearch, setShowSearch] = useState(false);

  const getTableHistory = async () => {
    const getDefaultDate = new Date(filterDate);
    let defaultDay = getDefaultDate.getDay();
    let defaultMonth = getDefaultDate.getMonth();
    let defaultDateNumber = getDefaultDate.getDate();
    let year = getDefaultDate.getFullYear();
    const defaultDate = `${dayDefault[defaultDay]} ${monthDefaultFormat[defaultMonth]} ${defaultDateNumber} ${year}`;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/payrollHistory`,
        {
          type: "getHistory",
          date: defaultDate,
          bagian: filterBagian,
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.data.code === 404) {
        setStatusCode(
          response.data.code !== undefined ? response.data.code : ""
        );
      } else {
        setDataHistory(sortByName(response.data));
      }
    } catch (error) {
      return error;
    }
  };

  const searchRiwayat = (params) => {
    if (paramSearch === "nameId" && valueSearch !== "") {
      let searchName = [];
      dataHistory &&
        dataHistory.forEach((value) => {
          if (value.name.toLowerCase().includes(params.toLowerCase())) {
            searchName.push(value);
          }
          if (value.id.includes(params)) {
            searchName.push(value);
          }
        });
      return searchName;
    } else {
      return dataHistory;
    }
  };

  let riwayatTemp = searchRiwayat(valueSearch);

  const handleSearch = () => {
    setShowSearch(true);
    getTableHistory();
  };

  const handleCancelExport = () => {
    setPopupExport(false);
    setValueEmployeeExport("");
  };

  const handleExportRequest = () => {
    handleExportPayroll();
    handleCancelExport();
  };

  const handleExportPayrollMingguan = () => {
    let dataSorted = [];

    for (let i = 0; i < dataHistory.length; i++) {
      const validatePermanent = () => {
        if (dataHistory[i].employeeType === "Permanent") {
          return true;
        } else {
          return false;
        }
      };

      const data = {
        alamat: dataHistory[i].alamat,
        bagian: dataHistory[i].bagian,
        bankAccountNumber: dataHistory[i].bankAccountNumber,
        basicSallary: dataHistory[i].basicSallary,
        bpjsK: uangBpjs ? dataHistory[i].bpjsK : 0,
        bpjsTK: uangBpjs ? dataHistory[i].bpjsTK : 0,
        email: dataHistory[i].email,
        employeeType: dataHistory[i].employeeType,
        id: dataHistory[i].id,
        jamOT: dataHistory[i].jamOT,
        jamOTLibur: dataHistory[i].jamOTLibur,
        jumlahAnak: dataHistory[i].jumlahAnak,
        kasbon: dataHistory[i].kasbon,
        kasbonTemp: dataHistory[i].kasbonTemp,
        kurangBayar: dataHistory[i].kurangBayar,
        lebihBayar: dataHistory[i].lebihBayar,
        lemburLibur: dataHistory[i].lemburLibur,
        name: dataHistory[i].name,
        npwpNumber: dataHistory[i].npwpNumber,
        phoneNumber: dataHistory[i].phoneNumber,
        position: dataHistory[i].position,
        role: dataHistory[i].role,
        setengahHari: dataHistory[i].setengahHari,
        shift: dataHistory[i].shift,
        shiftSatu: dataHistory[i].shiftSatu,
        statusPerkawinan: dataHistory[i].statusPerkawinan,
        tempatTanggalLahir: dataHistory[i].tempatTanggalLahir,
        tim: dataHistory[i].tim,
        totalOT: validatePermanent() ? dataHistory[i].totalOT : 0,
        totalWorking: dataHistory[i].totalWorking,
        uangMakan: uangMakanTransport ? dataHistory[i].uangMakan : 0,
        uangTransport: uangMakanTransport ? dataHistory[i].uangTransport : 0,
        date: dataHistory[i].date,
        waktuCicilan: dataHistory[i].waktuCicilan,
        shiftOneDate: dataHistory[i].shiftOneDate,
        workingShiftOne: dataHistory[i].workingShiftOne,
        keteranganKurangBayar: dataHistory[i].keteranganKurangBayar,
        type: dataHistory[i].type,
      };
      dataSorted.push(data);
    }

    const d = new Date();
    const date = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();
    const day = d.getDay();

    let totalGajiSudahPotong = 0;
    let totalGajiSebelumPotong = 0;
    let totalBPJSTK = 0;
    let totalBPJSK = 0;
    let countBPJSTK = 0;
    let countBPJSK = 0;
    let countKasbon = 0;

    const filteredDataExcel =
      dataSorted &&
      dataSorted.map((obj) => {
        const daySallary = obj.basicSallary / 30;
        const halfDaySallary =
          obj.employeeType === "Permanent" && obj.type !== "history_contract"
            ? obj.setengahHari > 0 && obj.setengahHari / 3600 < 7
              ? obj.setengahHari / 3600 - 7
              : obj.employeeType === "Permanent" && obj.setengahHari / 3600 >= 7
              ? obj.setengahHari / 3600 - 14
              : obj.setengahHari / 3600
            : obj.employeeType === "Contract"
            ? obj.setengahHari / 3600
            : 0;

        const shiftOne = obj.type === "history_permanent" ? 0 : obj.shiftSatu;
        const lemburLibur =
          obj.type === "history_permanent" ? 0 : obj.lemburLibur;
        const lembur = obj.totalOT;
        const tidakMasuk = obj.tidakMasuk ? obj.tidakMasuk : 0;

        const jamOTLibur = obj.jamOTLibur ? obj.jamOTLibur : 0;
        const displayJamOT = obj.jamOT ? obj.jamOT : 0;
        const totalLembur = jamOTLibur + displayJamOT;
        const uangTransport = obj.uangTransport;
        const uangMakan = obj.uangMakan;
        const kurangBayar = obj.kurangBayar;
        const lebihBayar = obj.lebihBayar;
        const bpjsTK = () => {
          return obj.bpjsTK;
        };
        const bpjsK = () => {
          return obj.bpjsK;
        };
        const kasbon =
          obj.employeeType === "Permanent"
            ? obj.kasbon
            : obj.employeeType === "Contract"
            ? obj.kasbon
            : 0;

        const kasbonTemp = obj.kasbonTemp;

        const validateWorkingShiftOne = () => {
          const today = new Date(filterDate);
          const yesterday = new Date(`${obj.shiftOneDate} 00:00:00 GMT+0700`);
          today.setDate(today.getDate() - 1);
          if (yesterday.toDateString() === today.toDateString()) {
            return obj.workingShiftOne;
          } else {
            return 0;
          }
        };

        const getDatePayroll = new Date(obj.date);
        const getDayPayroll = getDatePayroll.getDate();

        const hariKerja = () => {
          if (obj.employeeType === "Permanent") {
            return 756000 / 25200;
          } else if (
            obj.employeeType === "Contract" &&
            getDayPayroll === 26 &&
            obj.totalWorking - validateWorkingShiftOne() > 327600
          ) {
            return 327600 / 25200;
          } else {
            return (obj.totalWorking - validateWorkingShiftOne()) / 25200;
          }
        };

        const totalGaji =
          obj.employeeType === "Permanent"
            ? obj.type === "history_contract"
              ? 0
              : obj.basicSallary
            : daySallary * hariKerja();
        const potonganTidakMasuk = (tidakMasuk / 7) * daySallary;
        const totalGajiSetengahHari = (daySallary / 7) * halfDaySallary;
        const totalUangShiftSatu = shiftOne * 4000;
        const totalLemburLibur = daySallary * 2 * lemburLibur;
        const totalOvertime =
          (daySallary / 7) * lembur + jamOTLibur * (daySallary / 7) * 2;
        const totalSebelumPotongan =
          totalGaji +
          totalGajiSetengahHari +
          totalUangShiftSatu +
          totalLemburLibur +
          totalOvertime +
          uangTransport +
          uangMakan +
          kurangBayar -
          lebihBayar -
          potonganTidakMasuk;

        const totalSallarySudahPotong = Math.round(
          totalSebelumPotongan - bpjsTK() - bpjsK() - kasbon
        );

        const totalSallaryBelumPotong = Math.round(totalSebelumPotongan);

        if (bpjsTK() !== 0) {
          countBPJSTK++;
        }
        if (bpjsK() !== 0) {
          countBPJSK++;
        }
        countKasbon = countKasbon + kasbonTemp;
        totalGajiSebelumPotong =
          totalGajiSebelumPotong + totalSallaryBelumPotong;
        totalBPJSK = totalBPJSK + bpjsK();
        totalBPJSTK = totalBPJSTK + bpjsTK();
        totalGajiSudahPotong = totalGajiSudahPotong + totalSallarySudahPotong;

        return {
          ID: obj.id,
          "No. Rekening": obj.bankAccountNumber,
          Nama: obj.name,
          Gaji: totalSallarySudahPotong,
          "Hari Kerja": hariKerja(),
          "Lembur Hari Minggu": lemburLibur,
          "Lembur Jam": totalLembur,
          Email: obj.email,
          Bagian: obj.bagian,
          "Kurang Bayar": obj.kurangBayar,
          Keterangan: obj.keteranganKurangBayar,
        };
      });

    const sortByNama = filteredDataExcel.sort(function (a, b) {
      if (a.Bagian < b.Bagian) {
        return -1;
      }
      if (a.Bagian > b.Bagian) {
        return 1;
      }
      return 0;
    });

    const Jumlah = [
      {
        ID: "",
        "No. Rekening": "Jumlah",
        Nama: "",
        Gaji: formatDot(totalGajiSudahPotong),
      },
      {
        ID: "",
        "No. Rekening": "Kasbon",
        Nama: "",
        Gaji: formatDot(countKasbon),
      },
      {
        ID: "",
        "No. Rekening": `BPJS Ketenaga Kerjaan (${countBPJSTK} Orang)`,
        Nama: "",
        Gaji: formatDot(totalBPJSTK),
      },
      {
        ID: "",
        "No. Rekening": `BPJS Kesehatan (${countBPJSK} Orang)`,
        Nama: "",
        Gaji: formatDot(totalBPJSK),
      },
      {
        ID: "",
        "No. Rekening": "Total",
        Nama: "",
        Gaji: formatDot(totalGajiSudahPotong),
      },
    ];
    const workSheet = XLSX.utils.json_to_sheet(sortByNama.concat(Jumlah));
    workSheet["!cols"] = [
      { wch: 18 },
      { wch: 28 },
      { wch: 30 },
      { wch: 13 },
      { wch: 10 },
      { wch: 17 },
      { wch: 11 },
      { wch: 35 },
      { wch: 16 },
      { wch: 13 },
      { wch: 35 },
    ];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Gaji Transfer");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Payroll Mingguan ${monthFormat[month]} ${year}.xlsx`
    );
  };

  const exportHistoryPayrollMingguan = () => {
    const d = new Date();
    const month = d.getMonth();
    const year = d.getFullYear();
    const dataFilterExcel =
      dataHistory &&
      dataHistory.map((data) => {
        const validateWorkingShiftOne = () => {
          const today = new Date();
          const yesterday = new Date(`${data.shiftOneDate} 00:00:00 GMT+0700`);
          today.setDate(today.getDate() - 1);
          if (yesterday.toDateString() === today.toDateString()) {
            return data.workingShiftOne;
          } else {
            return 0;
          }
        };
        const d = new Date();
        const date = d.getDate();
        const day = d.getDay();
        const tidakMasuk = data.tidakMasuk ? data.tidakMasuk : 0;
        const totalWorking = () => {
          if (data.employeeType === "Permanent") {
            return 756000 - tidakMasuk * 3600;
          } else if (data.employeeType === "Contract") {
            // if (data.totalWorking > 352800) {
            //   return 352800;
            // } else {
            return data.totalWorking - validateWorkingShiftOne();
            // }
          }
        };
        const daySallary = Math.round(data.basicSallary / 30);
        const halfDaySallary =
          data.employeeType === "Permanent" && data.type !== "history_contract"
            ? data.setengahHari > 0 && data.setengahHari / 3600 < 7
              ? data.setengahHari / 3600 - 7
              : data.employeeType === "Permanent" &&
                data.setengahHari / 3600 >= 7
              ? data.setengahHari / 3600 - 14
              : data.setengahHari / 3600
            : data.employeeType === "Contract"
            ? data.setengahHari / 3600
            : 0;
        const shiftOne = data.shiftSatu;
        const lemburLibur = data.lemburLibur;
        const lembur = data.totalOT;
        const jamOTLibur = data.jamOTLibur ? data.jamOTLibur : 0;
        const displayJamOT = data.jamOT ? data.jamOT : 0;
        const uangTransport = uangMakanTransport ? data.uangTransport : 0;
        const uangMakan = uangMakanTransport ? data.uangMakan : 0;
        const kurangBayar = data.kurangBayar;
        const lebihBayar = data.lebihBayar;
        const bpjsTK = uangBpjs ? data.bpjsTK : 0;
        const bpjsK = uangBpjs ? data.bpjsK : 0;
        const kasbon =
          data.employeeType === "Permanent"
            ? data.kasbon
            : data.employeeType === "Contract"
            ? data.kasbon
            : 0;

        const totalGaji = daySallary * (totalWorking() / 25200);
        const totalGajiSetengahHari = (daySallary / 7) * halfDaySallary;
        const totalUangShiftSatu = shiftOne * 4000;
        const totalLemburLibur = daySallary * 2 * lemburLibur;
        const totalOvertime =
          (daySallary / 7) * lembur + jamOTLibur * (daySallary / 7) * 2;
        const totalSebelumPotongan =
          totalGaji +
          totalGajiSetengahHari +
          totalUangShiftSatu +
          totalLemburLibur +
          totalOvertime +
          uangTransport +
          uangMakan +
          kurangBayar -
          lebihBayar -
          kasbon;
        const takeHomePay = totalSebelumPotongan - bpjsTK - bpjsK;
        return {
          ID: data.id,
          Nama: data.name,
          "Hari Kerja": totalWorking() / 25200,
          Gaji: (daySallary * totalWorking()) / 25200,
          "Gaji Setengah Hari": totalGajiSetengahHari,
          "Uang Shift 1": totalUangShiftSatu,
          "Lembur Libur": lemburLibur,
          "Lebih Waktu": displayJamOT,
          "Lembur Waktu Libur": jamOTLibur,
          "Uang Transport": uangTransport,
          "Uang Makan": uangMakan,
          "Potongan Kasbon": kasbon,
          "Kurang Bayar": kurangBayar,
          "Lebih Bayar": lebihBayar,
          "BPJS TK": bpjsTK,
          "BPJS K": bpjsK,
          Total: takeHomePay,
          "Jam Lebih": data.jamLebih,
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(dataFilterExcel);
    workSheet["!cols"] = [
      { wch: 18 },
      { wch: 35 },
      { wch: 9 },
      { wch: 11 },
      { wch: 16 },
      { wch: 10 },
      { wch: 11 },
      { wch: 10 },
      { wch: 17 },
      { wch: 13 },
      { wch: 11 },
      { wch: 14 },
      { wch: 11 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 9 },
    ];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Riwayat Gaji");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Payroll History ${monthFormat[month]} ${year}.xlsx`
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
          margin: "0 auto 24px auto",
        }}
      >
        <div
          className={subMenu === "payroll" ? "menu_active" : "menu"}
          onClick={() => handleSubMenu("payroll")}
        >
          Payroll
        </div>
        <div
          className={subMenu === "riwayat" ? "menu_active" : "menu"}
          onClick={() => handleSubMenu("riwayat")}
        >
          Riwayat
        </div>
      </div>
      {subMenu === "payroll" ? (
        <>
          <p style={{ fontWeight: "bold" }}>Payroll</p>
          <div className="flex_space_between" style={{ flexWrap: "wrap" }}>
            <Search valueSearch={(e) => setValueSearch(e.target.value)} />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <DropDown
                dataOption={jenisKaryawan}
                setFilter={setFilterJenisKaryawan}
                placeHolder="Jenis Karyawan"
              />
              <DropDown
                dataOption={BagianConstant}
                setFilter={setFilterRole}
                placeHolder="Pilih Bagian"
              />
              {jsonRole.role === "finance_admin" ||
              jsonRole.role === "super_user" ? (
                <div
                  className="reset_data_button"
                  onClick={() => setPopUp(true)}
                >
                  Reset
                </div>
              ) : (
                ""
              )}
              {popUp ? (
                <div className="popup_container">
                  <div className="popup_wrapper ">
                    <p style={{ lineHeight: "20px", fontSize: "14px" }}>
                      Apakah Kamu yakin untuk mereset data gaji karyawan?
                      <br />
                      Perhitungan Gaji akan dimulai dari awal lagi setelah data
                      direset.
                    </p>
                    <p style={{ lineHeight: "20px", fontSize: "14px" }}>
                      Mohon Pilih salah satu Jenis Karyawan yang ingin di reset
                      Datanya.
                    </p>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => setValueEmployeeType("Permanent")}
                        style={{ alignItems: "center", display: "flex" }}
                      >
                        <input
                          type="radio"
                          value="Permanent"
                          checked={valueEmployeeType === "Permanent"}
                        />
                        <label style={{ fontSize: "14px" }}>Permanen</label>
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        className="cancel_button"
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </button>
                      <button
                        className="reset_button"
                        style={
                          valueEmployeeType !== ""
                            ? {
                                background: "red",
                              }
                            : {
                                background: "pink",
                              }
                        }
                        onClick={() => handleResetData()}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className="export_btn"
                onClick={() => exportHistoryPayroll()}
              >
                Riwayat
              </div>
              <div className="export_btn" onClick={() => setPopupExport(true)}>
                Payroll
              </div>
              {popupExport ? (
                <div className="popup_container">
                  <div className="popup_wrapper ">
                    <p style={{ lineHeight: "20px", fontSize: "14px" }}>
                      Mohon Pilih salah satu Jenis Karyawan yang ingin di Export
                      Datanya.
                    </p>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => setValueEmployeeExport("Contract")}
                        className="radio_employee_type"
                      >
                        <input
                          type="radio"
                          value="Contract"
                          checked={valueEmployeeExport === "Contract"}
                        />
                        <label style={{ fontSize: "14px" }}>Kontrak</label>
                      </div>
                      <div
                        onClick={() => setValueEmployeeExport("Permanent")}
                        style={{ alignItems: "center", display: "flex" }}
                      >
                        <input
                          type="radio"
                          value="Permanent"
                          checked={valueEmployeeExport === "Permanent"}
                        />
                        <label style={{ fontSize: "14px" }}>Permanen</label>
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        className="cancel_button"
                        onClick={() => handleCancelExport()}
                      >
                        Cancel
                      </button>
                      <button
                        className="reset_button"
                        style={
                          valueEmployeeExport !== ""
                            ? {
                                background: "red",
                              }
                            : {
                                background: "pink",
                              }
                        }
                        onClick={() => handleExportRequest()}
                      >
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div style={{ width: "0px", minWidth: "100%", overflow: "auto" }}>
            <table className="table_dashboard">
              <tr>
                <th>No.</th>
                <th>Nama</th>
                <th>Bagian</th>
                <th>Jenis Karyawan</th>
              </tr>
              {dataTemp &&
                dataTemp.map((data, index) => {
                  return (
                    <Accordion
                      data={data}
                      uangMakanTransport={uangMakanTransport}
                      uangBpjs={uangBpjs}
                      index={index}
                      type="Payroll"
                      whitelistTanggal={whitelistTanggal}
                    />
                  );
                })}
            </table>
          </div>
        </>
      ) : (
        <>
          <p style={{ fontWeight: "bold" }}>Riwayat</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              marginBottom: "18px",
            }}
          >
            <div>
              <p>Pilih Tanggal</p>
              <input
                style={{ height: "27px", width: "188px" }}
                type="date"
                onChange={(e) => setFilterDate(e.target.value)}
              />
            </div>
            <div>
              <p>Pilih Bagian</p>
              <select
                style={{ width: "188px", height: "32px" }}
                value={filterBagian}
                onChange={(e) => setFilterBagian(e.target.value)}
              >
                <option value="" selected disabled hidden>
                  Pilih Bagian
                </option>
                {BagianConstant.map((data) => (
                  <option value={data.value}>{data.name}</option>
                ))}
              </select>
            </div>
            <div
              className="export_btn"
              style={{
                background: "#1d6f42",
                cursor: "pointer",
                color: "white",
                padding: "8px",
                borderRadius: "6px",
                fontSize: "14px",
                display: "flex",
                alignSelf: "flex-end",
                margin: "0",
              }}
              onClick={() => exportHistoryPayrollMingguan()}
            >
              Riwayat
            </div>
            <div
              className="export_btn"
              style={{
                background: "#1d6f42",
                cursor: "pointer",
                color: "white",
                padding: "8px",
                borderRadius: "6px",
                fontSize: "14px",
                display: "flex",
                alignSelf: "flex-end",
                margin: "0",
              }}
              onClick={() => handleExportPayrollMingguan()}
            >
              Payroll
            </div>
            <button
              style={{
                width: "70px",
                height: "32px",
                marginTop: "42px",
                cursor: "pointer",
              }}
              onClick={() => handleSearch()}
            >
              Cari
            </button>
          </div>
          {riwayatTemp ? (
            <Search valueSearch={(e) => setValueSearch(e.target.value)} />
          ) : (
            ""
          )}
          <>
            <div style={{ width: "0px", minWidth: "100%", overflow: "auto" }}>
              <table className="table_dashboard">
                <tr>
                  <th>No.</th>
                  <th>Nama</th>
                  <th>Bagian</th>
                  <th>Jenis Karyawan</th>
                </tr>
                {riwayatTemp &&
                  riwayatTemp.map((data, index) => {
                    return (
                      <Accordion
                        data={data}
                        uangMakanTransport={uangMakanTransport}
                        index={index}
                        type="Riwayat"
                        filterDate={filterDate}
                        whitelistTanggal={whitelistTanggal}
                      />
                    );
                  })}
              </table>
            </div>
          </>
        </>
      )}
    </>
  );
};
export default Payroll;
