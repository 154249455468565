import { useState, useEffect } from "react";
import { dayDefault, monthDefaultFormat } from "../../util/formatDate";
import axios from "axios";

const WhiteList = () => {
  const getToken = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;

  const getTim = localStorage.getItem("user");
  const jsonTim = JSON.parse(getTim);

  const today = new Date();
  const [dataWhiteList, setDataWhiteList] = useState();
  const [dataTanggal, setDataTanggal] = useState();
  const [id, setId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [refreshTanggal, setRefreshTanggal] = useState(false);
  const [subMenu, setSubMenu] = useState("login");
  const [tanggal, setTanggal] = useState(today);

  let tanggalPick = new Date(tanggal);
  let day = tanggalPick.getDay();
  let date = tanggalPick.getDate();
  let month = tanggalPick.getMonth();
  let year = tanggalPick.getFullYear();
  const fullDate = `${dayDefault[day]} ${monthDefaultFormat[month]} ${date} ${year}`;

  const handleGetWhiteList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "getWhiteList",
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setDataWhiteList(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  const handleAddWhiteList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "addWhiteList",
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setRefresh(true);
        setId("");
      }
    } catch (error) {
      return error;
    }
  };

  const handleAddTanggalWhitelist = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "addTanggalWhitelist",
          tanggal: fullDate,
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setRefreshTanggal(true);
        setTanggal(today);
      }
    } catch (error) {
      return error;
    }
  };

  const handleGetTanggalWhitelist = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "getTanggalWhitelist",
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setDataTanggal(response.data);
        setTanggal(today);
      }
    } catch (error) {
      return error;
    }
  };
  const sortTanggal =
    dataTanggal &&
    dataTanggal.sort(function (a, b) {
      return new Date(b.tanggal) - new Date(a.tanggal);
    });

  const handleDeleteTanggalWhitelist = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "deleteTanggalWhitelist",
          tanggal: data,
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setRefreshTanggal(true);
      }
    } catch (error) {
      return error;
    }
  };

  const handleDeleteWhiteList = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "deleteWhiteList",
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setRefresh(true);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    handleGetWhiteList();
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    handleGetTanggalWhitelist();
    setRefreshTanggal(false);
  }, [refreshTanggal]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "50%",
          margin: "0 auto 24px auto",
        }}
      >
        <div
          className={subMenu === "login" ? "menu_active" : "menu"}
          onClick={() => setSubMenu("login")}
        >
          Login
        </div>
        <div
          className={subMenu === "hariLibur" ? "menu_active" : "menu"}
          onClick={() => setSubMenu("hariLibur")}
        >
          Hari Libur
        </div>
      </div>
      {subMenu === "login" ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "12px 0 24px 0",
            }}
          >
            {/* <input
              className="input_box"
              type="number"
              placeholder="Nomor KTP"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
            <button
              className="cancel_button"
              style={{
                margin: "0 18px 0 0",
                background: "dodgerblue",
                color: "white",
                border: 0,
              }}
              onClick={() => handleAddWhiteList()}
            >
              Add
            </button> */}
          </div>
          <div style={{ width: "0px", minWidth: "100%", overflow: "auto" }}>
            <table className="table_dashboard">
              <tr>
                <th>No.</th>
                <th>Nomor KTP</th>
                <th>Nama</th>
                <th></th>
              </tr>
              {dataWhiteList &&
                dataWhiteList.map((data, index) => (
                  <tr>
                    <div style={{ marginLeft: "16px" }}>{index + 1}</div>
                    <td>{data.id}</td>
                    <td>{data.name}</td>
                    <td style={{ display: "flex", justifyContent: "flex-end" }}>
                      <button
                        className="reset_button"
                        style={{ background: "red", margin: 0 }}
                        onClick={() => handleDeleteWhiteList(data.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "12px 0 24px 0",
            }}
          >
            <input
              className="input_box"
              type="date"
              placeholder="Pilih Tanggal"
              value={tanggal}
              onChange={(e) => setTanggal(e.target.value)}
            />
            <button
              className="cancel_button"
              style={{
                margin: "0 18px 0 0",
                background: "dodgerblue",
                color: "white",
                border: 0,
              }}
              onClick={() => handleAddTanggalWhitelist()}
            >
              Add
            </button>
          </div>
          <div style={{ width: "0px", minWidth: "100%", overflow: "auto" }}>
            <table className="table_dashboard">
              <tr>
                <th>No</th>
                <th>Tanggal</th>
                <th></th>
              </tr>
              {sortTanggal &&
                sortTanggal.map((data, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data.tanggal}</td>
                    <td style={{ display: "flex", justifyContent: "flex-end" }}>
                      <button
                        className="reset_button"
                        style={{ background: "red", margin: 0 }}
                        onClick={() =>
                          handleDeleteTanggalWhitelist(data.tanggal)
                        }
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </>
      )}
    </>
  );
};
export default WhiteList;
