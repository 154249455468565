const ButtonEdit = (props) => {
  const { isEdit, handleKirim, handleEdit } = props;
  return (
    <>
      {isEdit ? (
        <div
          className="edit_btn"
          onClick={handleKirim}
          style={{ background: "red", height: "32px" }}
        >
          Kirim
        </div>
      ) : (
        <div className="edit_btn" onClick={handleEdit}>
          Edit
        </div>
      )}
    </>
  );
};
export default ButtonEdit;
