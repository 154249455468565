import { useState } from "react";
import {
  BagianConstant,
  RoleConstant,
  kepalaShiftTimOne,
  kepalaShiftTimTwo,
} from "../../constant";
import axios from "axios";
import "./AddUser.css";

const AddUser = () => {
  const getToken = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;

  const getTim = localStorage.getItem("user");
  const jsonGetTim = JSON.parse(getTim);

  const [popUp, setPopUp] = useState(false);

  const [nama, setNama] = useState("");
  const [ktpNumber, setKtpNumber] = useState("");
  const [npwpNumber, setNpwpNumber] = useState("");
  const [teleponNumber, setTeleponNumber] = useState("");
  const [email, setEmail] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [address, setAddress] = useState("");
  const [bagian, setBagian] = useState("");
  const [kepalaShift, setKepalaShift] = useState("");
  const [role, setRole] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [statusPerkawinan, setStatusPerkawinan] = useState("");
  const [totalChildren, setTotalChildren] = useState("");
  const [basicSallary, setBasicSallary] = useState("");
  const [position, setPosition] = useState("");
  const [tempatTanggalLahir, setTempatTanggalLahir] = useState("");
  const [uangMakan, setUangMakan] = useState("");
  const [uangTransport, setUangTransport] = useState("");
  const [bpjsK, setBpjsK] = useState("");
  const [bpjsTK, setBpjsTK] = useState("");
  const [kontrakAwal, setKontrakAwal] = useState("");
  const [statusProbation, setStatusProbation] = useState(true);

  const [isError, setIsError] = useState(false);

  const validateData =
    jsonGetTim.tim === 1 ? kepalaShiftTimOne : kepalaShiftTimTwo;

  const handleReset = () => {
    setNama("");
    setKontrakAwal("");
    setKtpNumber("");
    setNpwpNumber("");
    setTeleponNumber("");
    setEmail("");
    setBankNumber("");
    setAddress("");
    setBagian("");
    setRole("");
    setEmployeeType("");
    setStatusPerkawinan("");
    setTotalChildren("");
    setBasicSallary("");
    setPosition("");
    setTempatTanggalLahir("");
    setUangMakan("");
    setUangTransport("");
    setBpjsK("");
    setBpjsTK("");
    setKepalaShift("");
    setStatusProbation(false);
  };
  const validateNama = nama.split(" ").slice(0, 2).join(".");

  const handleTim =
    jsonGetTim.tim === 1 ? validateNama.toLowerCase() : validateNama;

  const handleAddUser = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/addUser`,
        {
          id: ktpNumber,
          name: nama,
          role: role,
          employeeType: employeeType,
          totalWorking: 0,
          totalOT: 0,
          basicSallary: Number(basicSallary),
          password: "Tim123",
          username: handleTim,
          position: position,
          alamat: address,
          npwpNumber: npwpNumber,
          phoneNumber: teleponNumber,
          email: email,
          bankAccountNumber: bankNumber,
          shiftSatu: 0,
          uangMakan: uangMakan === "" ? 0 : Number(uangMakan),
          uangTransport: uangTransport === "" ? 0 : Number(uangTransport),
          kurangBayar: 0,
          lebihBayar: 0,
          bpjsTK: bpjsTK === "" ? 0 : Number(bpjsTK),
          bpjsK: bpjsK === "" ? 0 : Number(bpjsK),
          lemburLibur: 0,
          setengahHari: 0,
          shift: 1,
          bagian: bagian,
          kasbon: 0,
          kasbonTemp: 0,
          statusPerkawinan: statusPerkawinan,
          jumlahAnak: Number(totalChildren),
          workingShiftOne: 0,
          waktuCicilan: 0,
          token: "",
          deviceId: "",
          jamLebih: 0,
          tempatTanggalLahir: tempatTanggalLahir,
          tim: jsonGetTim.tim,
          kontrakAwal: kontrakAwal,
          kepalaShift: kepalaShift,
          probation: statusProbation,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        setPopUp(true);
      } else {
        setIsError(true);
      }
    } catch (error) {
      return error;
    }
  };

  const handleClosePopUp = () => {
    handleReset();
    setPopUp(false);
  };

  return (
    <div style={{ width: "0px", minWidth: "100%", overflow: "auto" }}>
      <div>
        {popUp ? (
          <div className="pop_up_container">
            <div className="pop_up_wrapper">
              <div className="flex_column_center">
                <p style={{ textAlign: "center", marginBottom: "18px" }}>
                  {isError
                    ? "User Telah Ada di Database, mohon periksa lagi No KTP!"
                    : "User Baru telah berhasil ditambahkan!"}
                </p>
                <button
                  className="OK_button"
                  onClick={() => handleClosePopUp()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <p style={{ fontWeight: "bold", marginBottom: "18px" }}>Add User</p>
        <div className="border_1px" />
        <div className="flex_space_between">
          <div>
            <p>Nama</p>
            <input
              className="input_box"
              type="text"
              value={nama}
              onChange={(e) => setNama(e.target.value)}
            />
          </div>
          <div>
            <p>No. KTP</p>
            <input
              className="input_box"
              type="number"
              value={ktpNumber}
              onChange={(e) => setKtpNumber(e.target.value)}
            />
          </div>

          <div>
            <p>No. NPWP</p>
            <input
              className="input_box"
              type="number"
              value={npwpNumber}
              onChange={(e) => setNpwpNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="flex_space_between">
          <div>
            <p>No. Telepon</p>
            <input
              className="input_box"
              type="number"
              value={teleponNumber}
              onChange={(e) => setTeleponNumber(e.target.value)}
            />
          </div>
          <div>
            <p>Email</p>
            <input
              className="input_box"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <p>No. Rekening</p>
            <input
              className="input_box"
              type="number"
              value={bankNumber}
              onChange={(e) => setBankNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="flex_space_between">
          <div>
            <p>Alamat</p>
            <textarea
              className="text_area"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div>
            <div>
              <p>Bagian</p>
              <select
                style={{ width: "188px", height: "32px" }}
                value={bagian}
                onChange={(e) => setBagian(e.target.value)}
              >
                <option value="" selected disabled hidden>
                  Pilih Bagian
                </option>
                {BagianConstant.map((data) => (
                  <option value={data.value}>{data.name}</option>
                ))}
              </select>
            </div>
            <div>
              <p>Role</p>
              <select
                style={{ width: "188px", height: "32px" }}
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="" selected disabled hidden>
                  Pilih Bagian
                </option>
                {RoleConstant.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex_space_between">
          <div>
            <p>Jenis Karyawan</p>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setEmployeeType("Permanent")}
                className="flex_align_center"
              >
                <input
                  type="radio"
                  value="Permanent"
                  checked={employeeType === "Permanent"}
                />
                <label style={{ fontSize: "14px" }}>Permanen</label>
              </div>
              <div
                onClick={() => setEmployeeType("Contract")}
                className="flex_align_center"
              >
                <input
                  type="radio"
                  value="Contract"
                  checked={employeeType === "Contract"}
                />
                <label style={{ fontSize: "14px" }}>Kontrak</label>
              </div>
            </div>
          </div>
          <div>
            <p>Status Perkawinan</p>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setStatusPerkawinan("KAWIN")}
                className="flex_align_center"
              >
                <input
                  type="radio"
                  value="KAWIN"
                  checked={statusPerkawinan === "KAWIN"}
                />
                <label style={{ fontSize: "14px" }}>Kawin</label>
              </div>
              <div
                onClick={() => setStatusPerkawinan("TIDAK KAWIN")}
                className="flex_align_center"
              >
                <input
                  type="radio"
                  value="TIDAK KAWIN"
                  checked={statusPerkawinan === "TIDAK KAWIN"}
                />
                <label style={{ fontSize: "14px" }}>Tidak Kawin</label>
              </div>
            </div>
          </div>
          <div>
            <p>Jumlah Anak</p>
            <input
              className="input_box"
              type="number"
              value={totalChildren}
              onChange={(e) => setTotalChildren(e.target.value)}
            />
          </div>
        </div>
        <div className="flex_space_between">
          <div>
            <p>BPJS Kesehatan</p>
            <input
              className="input_box"
              type="number"
              value={bpjsK}
              onChange={(e) => setBpjsK(e.target.value)}
            />
          </div>
          <div>
            <p>BPJS Tenaga Kerja</p>
            <input
              className="input_box"
              type="number"
              value={bpjsTK}
              onChange={(e) => setBpjsTK(e.target.value)}
            />
          </div>
          <div>
            <p>Uang Makan</p>
            <input
              className="input_box"
              type="number"
              value={uangMakan}
              onChange={(e) => setUangMakan(e.target.value)}
            />
          </div>
        </div>
        <div className="flex_space_between">
          <div>
            <p>Uang Transport</p>
            <input
              className="input_box"
              type="number"
              value={uangTransport}
              onChange={(e) => setUangTransport(e.target.value)}
            />
          </div>
          <div>
            <p>Posisi</p>
            <input
              className="input_box"
              type="text"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </div>
          <div>
            <p>Tempat Tanggal Lahir</p>
            <input
              className="input_box"
              type="text"
              value={tempatTanggalLahir}
              onChange={(e) => setTempatTanggalLahir(e.target.value)}
            />
          </div>
        </div>
        <div className="flex_space_between">
          <div>
            <p>Gaji Pokok</p>
            <input
              className="input_box"
              type="number"
              value={basicSallary}
              onChange={(e) => setBasicSallary(e.target.value)}
            />
          </div>
          <div>
            <p>Kontrak Awal</p>
            <input
              className="input_box"
              type="text"
              value={kontrakAwal}
              onChange={(e) => setKontrakAwal(e.target.value)}
            />
          </div>
          <div>
            <p>Kepala Shift</p>
            <select
              style={{ width: "188px", height: "32px" }}
              value={kepalaShift}
              onChange={(e) => setKepalaShift(e.target.value)}
            >
              <option value="" selected disabled>
                Pilih Kepala
              </option>
              {validateData.map((data) => (
                <option value={data.value}>{data.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex_space_between">
          <div style={{ width: "180px" }}>
            <p>Status Percobaan</p>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setStatusProbation(true)}
                className="flex_align_center"
                style={{ margin: "6px" }}
              >
                <input
                  type="radio"
                  value="YA"
                  checked={statusProbation === true}
                />
                <label style={{ fontSize: "14px" }}>YA</label>
              </div>

              <div
                onClick={() => setStatusProbation(false)}
                className="flex_align_center"
                style={{ margin: "6px" }}
              >
                <input
                  type="radio"
                  value="TIDAK"
                  checked={statusProbation === false}
                />
                <label style={{ fontSize: "14px" }}>TIDAK</label>
              </div>
            </div>
          </div>
        </div>
        <div className="container_button" style={{ float: "right" }}>
          <button
            className="reset_button_add_user"
            onClick={() => handleReset()}
          >
            Reset
          </button>
          <button className="save_button" onClick={() => handleAddUser()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddUser;
