import { useState, useEffect } from "react";
import { monthDefaultFormat } from "../../util/formatDate";
import axios from "axios";

const Kasbon = () => {
  const getToken = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;

  const getTim = localStorage.getItem("user");
  const jsonTim = JSON.parse(getTim);
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth();
  const year = today.getFullYear();

  const [ktpNumber, setKtpNumber] = useState("");
  const [nominal, setNominal] = useState(0);
  const [tenor, setTenor] = useState(0);
  const [subMenu, setSubMenu] = useState("addKasbon");
  const [dataHistory, setDataHistory] = useState();

  const formatDot = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleReset = () => {
    setKtpNumber("");
    setNominal(0);
    setTenor(0);
  };

  const handleSubmit = async () => {
    if (ktpNumber !== "" && nominal !== 0 && tenor !== 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/kasbon`,
          {
            type: "addKasbon",
            id: ktpNumber,
            kasbonTemp: nominal,
            waktuCicilan: tenor,
            date: `${date} ${monthDefaultFormat[month]} ${year}`,
            tim: jsonTim.tim,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken}`,
            },
          }
        );
        if (response.status === 200) {
          handleReset();
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleHistory = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/kasbon`,
        {
          type: "historyKasbon",
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.data.status.code) {
        const sortedData = response.data.data.sort(function (a, b) {
          let dateA = new Date(a.date),
            dateB = new Date(b.date);
          return dateB - dateA;
        });
        setDataHistory(sortedData);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (subMenu === "historyKasbon") {
      handleHistory();
    }
  }, [subMenu]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
          margin: "auto",
        }}
      >
        <p
          className={subMenu === "addKasbon" ? "menu_active" : "menu"}
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => setSubMenu("addKasbon")}
        >
          Buat Kasbon
        </p>
        <p
          className={subMenu === "historyKasbon" ? "menu_active" : "menu"}
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => setSubMenu("historyKasbon")}
        >
          Riwayat Kasbon
        </p>
      </div>
      <div style={{ width: "0px", minWidth: "100%", overflow: "auto" }}>
        {subMenu === "addKasbon" ? (
          <>
            <div
              style={{
                display: "flex",
                width: "580px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p>Nomor KTP</p>
                <input
                  className="input_box"
                  type="number"
                  value={ktpNumber}
                  onChange={(e) => setKtpNumber(e.target.value)}
                />
              </div>
              <div>
                <p>Nominal Kasbon</p>
                <input
                  className="input_box"
                  type="number"
                  value={nominal}
                  onChange={(e) => setNominal(Number(e.target.value))}
                />
              </div>
              <div>
                <p>Berapa kali Cicilan</p>
                <input
                  className="input_box"
                  type="number"
                  value={tenor}
                  onChange={(e) => setTenor(Number(e.target.value))}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button className="cancel_button" onClick={() => handleReset()}>
                Reset
              </button>
              <button
                className="reset_button"
                style={
                  ktpNumber !== "" && nominal !== 0 && tenor !== 0
                    ? {
                        background: "red",
                      }
                    : {
                        background: "pink",
                      }
                }
                onClick={() => handleSubmit()}
              >
                Save
              </button>
            </div>
          </>
        ) : (
          <>
            <table className="table_dashboard">
              <tr>
                <th>Nomor KTP</th>
                <th>Nama</th>
                <th>Nominal</th>
                <th>Tanggal</th>
                <th>Waktu Cicilan (kali)</th>
              </tr>
              {dataHistory &&
                dataHistory.map((data) => (
                  <tr>
                    <td>{data.user_id}</td>
                    <td>{data.name}</td>
                    <td>Rp. {formatDot(data.kasbonTemp)}</td>
                    <td>{data.date}</td>
                    <td>{data.waktuCicilan}</td>
                  </tr>
                ))}
            </table>
          </>
        )}
      </div>
    </>
  );
};
export default Kasbon;
